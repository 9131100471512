import sd from '../../assets/lottiefiles/software_developer.json'
import cw from '../../assets/lottiefiles/content_writer.json'
import gd from '../../assets/lottiefiles/graphic_designer.json'
import tr from '../../assets/lottiefiles/trainer.json'


export const EDUCATION = [
    { label: 'BCA' },
    { label: 'MCA' },
    { label: 'BSc.CS|IT' },
    { label: 'MSc.CS|IT' },
    { label: 'BE.CS|IT|ECE' },
    { label: 'ME.CS|IT|ECE' },
    { label: 'BTech.CS|IT|ECE' },
    { label: 'MTech.CS|IT|ECE' },
    { label: 'PhD.CS|IT|ECE' },
]

export const WORK_EXPERIENCE = [
    { label: 'Fresher' },
    { label: '0 - 1 Year' },
    { label: '1 - 2 Year' },
    { label: '2+ Years' },
]
export const DATA = [
    {
        Title: 'Software Developer',
        BasicDescription: "Innovate and code with impact as a Software Developer, shaping the future of cutting-edge applications. Be a key player in our tech-driven team, bringing software solutions to life and embracing challenges as opportunities.",
        Description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        Icon: sd,
        BG: '/assets/images/careers/software_developer.jpg',
        Vaccants: 0,
        Skills: [
            'Skill 1',
            'Skill 2',
            'Skill 3',
            'Skill 4',
            'Skill 5',
            'Skill 6',
        ],
        bgcolor: '#C6FF55E0'
    },
    {
        Title: 'Content Writer',
        BasicDescription: "Craft engaging narratives that transcend the boundaries of academia. Join us as a Content Writer for Research Articles, where your words breathe life into groundbreaking discoveries and captivate the minds of the scholarly community.",
        Description: "Are you a talented wordsmith with a deep understanding of technical concepts? At Genesys Academy, we're in search of skilled Content Writers to join our team. We value candidates who possess an advanced degree (M.E/M.Sc/M.C.A) in domains related to computer science and have a passion for effective communication.",
        Icon: cw,
        BG: '/assets/images/careers/content_writer.jpg',
        Vaccants: 2,
        Skills: [
            'Technical Knowledge',
            'Vocabulary Excellence',
            'Fast Learner',
            'Article/Thesis Writing Support',
            'Experienced candidates are highly valued in this role.',
        ],
        bgcolor: '#A2E8D7E0'
    },
    {
        Title: 'Graphic Designer',
        BasicDescription: "Infuse creativity into every design as a Graphic Designer, visually representing our brand and ideas. Join our team to design visually stunning experiences, where your design prowess transforms concepts into captivating visuals.",
        Description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        Icon: gd,
        BG: '/assets/images/careers/designer.jpg',
        Vaccants: 2,
        Skills: [
            'Skill 1',
            'Skill 2',
            'Skill 3',
            'Skill 4',
            'Skill 5',
            'Skill 6',
        ],
        bgcolor: '#9CC0F9E0'
    },
    {
        Title: 'Trainers',
        BasicDescription: "Inspire minds and nurture talent as a Software Trainer, sharing expertise in our transformative learning environment. Play a pivotal role in shaping the next generation of tech professionals through engaging training programs.",
        Description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        Icon: tr,
        BG: '/assets/images/careers/trainer.jpg',
        Vaccants: 0,
        Skills: [
            'Skill 1',
            'Skill 2',
            'Skill 3',
            'Skill 4',
            'Skill 5',
            'Skill 6',
        ],
        bgcolor: '#FFC8BBE0'
    },
]