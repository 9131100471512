import React from "react"
import { Grid, Typography, CardMedia, Button } from "@mui/material"
import { size, COLORS } from "../../utils";
import { DATA } from '../Workshops/Data'


export default function Workshops() {
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            m={`${size({ p: 15, h: true })} 0px`} rowGap={size({ p: 5, h: true })}
            padding={`0px ${size({ p: 10 })}`}
        >
            <Grid item container flexWrap={'nowrap'} direction={'column'}
                rowGap={size({ p: 2, h: true })}>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 2.2 }),
                            fontWeight: 600,
                            textAlign: 'center',
                            color: COLORS.C1,
                            padding: `0px ${size({ p: 5 })}`
                        }}
                    >
                        We offer a diverse array of workshops covering both emerging and existing technologies with our comprehensive offerings
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 1 }),
                            fontWeight: 400,
                            textAlign: 'center',
                            color: `${COLORS.C1}A0`,
                            padding: `0px ${size({ p: 2 })}`
                        }}
                    >
                        Start your learning adventure with our diverse selection of workshops on today's hottest technologies. Embark on a journey of continuous learning with our diverse range of workshops, meticulously curated to cover both emerging and established technologies. Elevate your skills and stay ahead in the dynamic world of technology with our immersive and hands-on sessions. Embark on a transformative learning adventure with our array of workshops, carefully curated to cover the hottest technologies of today. Explore hands-on sessions, dive deep into emerging trends, and ignite your passion for continuous learning. Join us to stay at the forefront of the ever-evolving tech landscape.

                    </Typography>
                </Grid>
            </Grid>
            <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
                rowGap={size({ p: 1, h: true })}>
                <Grid item>
                    <Typography sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 2.2 }),
                        fontWeight: 700,
                        color: COLORS.C1,
                    }}>
                        Association with
                    </Typography>
                </Grid>
                <Grid item container flexWrap={'nowrap'} alignItems={'center'}
                    p={`0px ${size({ p: 15 })}`}>
                    {DATA.ASSOCIATED.map((k) => {
                        return (
                            <Grid item container flexWrap={'nowrap'} rowGap={size({ p: 1, h: true })}
                                direction={'column'} alignItems={'center'}
                                justifyContent={'center'} sx={{
                                    padding: size({ p: 1 }),
                                    cursor: 'pointer',
                                }}>
                                <Grid item sx={{
                                    width: size({ p: 6 }),
                                    height: size({ p: 6 }),
                                    backgroundColor: COLORS.BLACK + '20',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <CardMedia
                                        component={'img'}
                                        image={k[1]}
                                        sx={{ width: size({ p: 4 }), height: size({ p: 4 }) }} />
                                </Grid>
                                <Grid item>
                                    <Typography sx={{
                                        fontFamily: 'Space Mono',
                                        fontSize: size({ p: 1 }),
                                        fontWeight: 600,
                                        color: `${COLORS.C1}`,
                                    }}>{k[0]}</Typography>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            <Grid item>
                <Button variant="contained"
                    onClick={() => {
                    }}
                    sx={{
                        backgroundColor: `${COLORS.C1}`,
                        fontFamily: 'Archia',
                        fontWeight: 500,
                        color: COLORS.WHITE,
                        fontSize: size({ p: 1 }),
                        textTransform: 'none',
                        borderRadius: size({ p: 3 }),
                        ':hover': {
                            backgroundColor: `${COLORS.C1}`,
                        },
                    }}>{'Explore Courses'}</Button>
            </Grid>
        </Grid>
    )
}
