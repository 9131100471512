import * as React from "react";

function PauseIcon(props) {
    return (
        <svg
            viewBox="0 0 320 512"
            fill="currentColor"
            height="1em"
            width="1em"
            {...props}
        >
            <path d="M48 64C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48h-32z" />
        </svg>
    );
}

export default PauseIcon;
