import React from "react";
import { size, COLORS } from "../../utils";
import { Grid, Typography, CardMedia, Button } from "@mui/material"

export default function Intro() {
    return (
        <Grid item container flexWrap={'nowrap'} alignItems={'center'} justifyContent={'center'}
            width={size({ p: 100 })} height={size({ p: 90, h: true })} columnGap={size({ p: 3 })}>
            <Grid item container flexWrap={'nowrap'} direction={'column'} width={size({ p: 30 })}
                height={size({ p: 50, h: true })} justifyContent={'center'}
                rowGap={size({ p: 0.2, h: true })}>
                <Grid item>
                    <Typography component={'span'}
                        sx={{
                            fontFamily: 'Space Mono',
                            fontSize: size({ p: 1 }),
                            fontWeight: 400,
                            textAlign: 'left',
                            color: COLORS.C2,
                        }}
                    >
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography component={'span'}
                        sx={{
                            fontFamily: 'Space Mono',
                            fontSize: size({ p: 2.5 }),
                            fontWeight: 700,
                            textAlign: 'left',
                            textTransform: 'uppercase',
                            wordSpacing: size({ p: 0.6, neg: true }),
                            color: COLORS.C2,
                        }}
                    >
                        #1 PhD Assistance
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Space Mono',
                            fontSize: size({ p: 1 }),
                            fontWeight: 400,
                            textAlign: 'left',
                            color: COLORS.BLACK + 'A0',
                        }}
                    >
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained"
                        onClick={() => {
                            document.getElementById('ra_reach_us').scrollIntoView({
                                behavior: 'smooth', block: 'center'
                            })
                        }}
                        sx={{
                            backgroundColor: `${COLORS.C2}`,
                            fontFamily: 'Archia',
                            fontWeight: 400,
                            fontSize: size({ p: 1 }),
                            color: COLORS.WHITE,
                            textTransform: 'none',
                            ':hover': {
                                backgroundColor: `${COLORS.C2}`,
                            },
                            marginTop: size({ p: 3, h: true })
                        }}>{'Reach Us Now'}</Button>
                </Grid>
            </Grid>
            <Grid item>
                <CardMedia
                    component={'img'}
                    image={'/assets/images/research_assistance/research.svg'}
                    sx={{
                        width: size({ p: 40 }),
                        height: size({ p: 40 }),
                        objectFit: 'contain'
                    }} />
            </Grid>
        </Grid>
    )
}
