import React from "react"
import { Dialog, Slide, Grid, CardMedia, Typography, TextField as TF, Button } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { size, COLORS } from "../../utils"
import space from '../../assets/lottiefiles/space.json'
import Lottie from 'react-lottie';

const useStyles = makeStyles(theme => ({
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderWidth: size({ p: 0 }),
        }
    },
    cssFocused: {
        borderWidth: size({ p: 0 }),
    },
    notchedOutline: {
        borderWidth: size({ p: 0 }),
    },
}))

function TextField({ label, tf_props }) {
    const classes = useStyles()
    return (
        <Grid item container direction={'column'}>
            <Grid item>
                <Typography
                    sx={{
                        fontFamily: 'Space Mono',
                        fontSize: size({ p: 1 }),
                        fontWeight: 400,
                        wordSpacing: size({ p: 0.2, neg: true }),
                        marginBottom: size({ p: 0.5 }),
                        color: `${COLORS.WHITE}A0`,
                    }}
                >{label}</Typography>
            </Grid>
            <Grid item>
                <TF
                    placeholder={label}
                    {...tf_props}
                    InputProps={{
                        style: {
                            fontFamily: 'Space Mono',
                            color: COLORS.WHITE,
                            backgroundColor: '#242424',
                            fontSize: size({ p: 1 })
                        },
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}


export default function Enroll(props) {
    const { onClose, open, data } = props
    return (
        <Dialog open={open} TransitionComponent={Slide} fullScreen>
            <div style={{
                width: size({ p: 100 }),
                height: size({ p: 100, h: true }),
                backgroundColor: COLORS.WHITE,
                backgroundImage: `
                    linear-gradient(${COLORS.C5}40 ${size({ p: 0.06 })}, transparent ${size({ p: 0.06 })}), 
                    linear-gradient(to right, ${COLORS.C5}40 ${size({ p: 0.06 })}, ${COLORS.WHITE} ${size({ p: 0.06 })})
                    `,
                backgroundSize: `${size({ p: 2.5 })} ${size({ p: 2 })}`,
                position: 'relative',
                display: 'grid',
                alignItems: 'center',
            }}>
                <div style={{
                    position: "absolute",
                    left: size({ p: 50 }),
                }}>
                    <Lottie width={size({ p: 40 })} height={size({ p: 40 })}
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: space,
                        }} />
                </div>
                <div style={{
                    marginLeft: size({ p: 18 }),
                    position: 'relative',
                }}>
                    <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
                        justifyContent={'start'} width={size({ p: 28 })} height={size({ p: 74, h: true })}
                        rowGap={size({ p: 1.5 })} sx={{
                            backgroundColor: COLORS.C5,
                            borderRadius: size({ p: 0.6 }),
                            position: 'relative'
                        }}>
                        <Grid item>
                            <Typography onClick={() => onClose()} sx={{
                                fontFamily: 'Space Mono',
                                fontSize: size({ p: 3 }),
                                color: COLORS.WHITE,
                                position: 'absolute',
                                cursor: 'pointer',
                                right: size({ p: 2 }),
                            }}>
                                x
                            </Typography>
                        </Grid>
                        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
                            justifyContent={'center'} rowGap={size({ p: 0.5 })}>
                            <Grid item>
                                <CardMedia
                                    component={'img'}
                                    image={data.icon} sx={{
                                        width: size({ p: 7 }),
                                        height: size({ p: 7 })
                                    }} />
                            </Grid>
                            <Grid item>
                                <Typography sx={{
                                    fontFamily: 'Archia',
                                    fontSize: size({ p: 0.9 }),
                                    fontWeight: 400,
                                    color: `${COLORS.WHITE}A0`,
                                    textAlign: 'center'
                                }}>Enroll Your Details</Typography>
                                <Typography sx={{
                                    fontFamily: 'Archia',
                                    fontSize: size({ p: 1.8 }),
                                    fontWeight: 600,
                                    color: COLORS.WHITE,
                                    textAlign: 'center'
                                }}>{data.name}</Typography>
                                <Typography sx={{
                                    fontFamily: 'Archia',
                                    fontSize: size({ p: 0.9 }),
                                    fontWeight: 400,
                                    color: `${COLORS.WHITE}A0`,
                                    textAlign: 'center'
                                }}>99,888 Students Certified</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
                            justifyContent={'center'} rowGap={size({ p: 1 })}>
                            <Grid item width={size({ p: 24 })}>
                                <TextField label="Name" tf_props={{
                                    fullWidth: 'true'
                                }} />
                            </Grid>
                            <Grid item width={size({ p: 24 })}>
                                <TextField label="Contact Number" tf_props={{
                                    fullWidth: 'true'
                                }} />
                            </Grid>
                        </Grid>
                        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
                            justifyContent={'center'} rowGap={size({ p: 0.3 })}>
                            <Grid item width={size({ p: 24 })}>
                                <Button variant="contained" fullWidth sx={{
                                    backgroundColor: `${COLORS.C6}`,
                                    fontFamily: 'Archia',
                                    fontWeight: 400,
                                    fontSize: size({ p: 1 }),
                                    color: `${COLORS.WHITE}`,
                                    textTransform: 'none',
                                    ':hover': {
                                        backgroundColor: `${COLORS.C6}`,
                                    },
                                }}>Enroll Now</Button>
                            </Grid>
                            <Grid item width={size({ p: 24 })}>
                                <Typography fullWidth sx={{
                                    fontFamily: 'Space Mono',
                                    fontSize: size({ p: 0.7 }),
                                    fontWeight: 400,
                                    color: `${COLORS.WHITE}A0`,
                                    textAlign: 'center'
                                }}>Thank you for filling out the form, someone from our team will contact you soon.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Dialog>
    )
}