export const DATA = {
    'Skill level': 'Beginner',
    'Time to complete': 'Approx. 45 hours',
    'Certificate': 'Included',
    Prerequesties: 'None',
    description: 'Learn the basics of Python 3, one of the most powerful, versatile, and in-demand programming languages today.',
    about: 'If you’re looking for a programming language that’s flexible and easy to read, try learning Python. It’s one of the most popular languages today, and programming in Python is used for everything from web and software development to data science and analytics to quality assurance.',
    skills: [
        'Write Python 3 programs',
        'Simplify the code you write',
        'Take your skills off-platform'
    ],
    reviews: {
        1: {
            name: 'Miriam.H',
            review: "I really enjoyed this course. The most difficult parts were succinctly explained in detail and had witty banter thrown in so the tasks weren't too serious. The video walkthroughs are really useful and everything is clarified really well. I highly recommend this course if you want to learn the essentials of Python!",
        },
        2: {
            name: 'Raymond M',
            review: "This is one of the best Python courses I've ever taken. The assignments and projects make a significant difference in learning programming. The course is well-explained, with exercises that review what you have learned.",
        },
        3: {
            name: 'Brandon B',
            review: "These courses are excellent for beginners! They have the perfect balance of theory, practice examples, and hands-on projects. It genuinely makes learning these complex topics easy and fun. I honestly can't think of a single critique. Thank you Genesys!",
        }
    },
    modules: {
        Introduction: [
            'Python History',
            'Why should you learn PYTHON?',
            'Python Features',
            'Program Development Life Cycle',
            'Python Installation',
            'HELLO WORLD'
        ],
        Constructs: [
            'Input/Output Statements',
            'Identifiers',
            'Naming Rules for an Identifier',
            'Underscores in Python',
            'Keywords',
            'Indentation',
            'Multiline Statements',
            'Comments',
        ],
        'Data Types': [
            'Why Data Types',
            'Static vs Dynamic Typed Programming',
            'Built-In Data Types',
            'Numbers',
            'Boolean',
            'NoneType',
            'String',
            'List',
            'Tuple',
            'Dictionary',
            'Set',
        ],
        'Control Flow': [
            'If',
            'If...Else',
            'Else...If Ladder',
        ],
        Loops: [
            'For',
            'For...Else',
            'While',
            'While...Else',
            'Loop Control Statements'
        ],
        Comprehension: [
            'List',
            'Tuple',
            'Dict',
            'Set',
        ],
        Functions: [
            'Definition',
            'Call',
            'Types Of Arguments',
            'Lambda Functions',
            'Recursive Functions',
            'Global vs Local Variables',
            'Buil-In Functions',
            'Closures',
            'Decorators',
            'Iterators',
            'Generators',
        ],
        'Object-Oriented Programming': [
            "What is OOP's",
            'Procedural vs Object-Oriented Programming'
        ],
        "OOP's Concepts": [
            'Class and Objects',
            'Defining a class',
            'Instantiate an Object in Python Instance',
            '"self" Argument',
            'Class vs Instance Attributes',
            'Class vs Instance vs Static Methods',
            'Look back on underscores',
            'Inheritance',
            'Types Of Inheritance',
            'Methods Overloading',
            'Methods Overriding',
            'Magic Methods',
        ],
        'Exception Handling': [
            'try...except',
            'try...finally',
            'try...except...finally',
            'try...except...else',
            'Built-In Exceptions',
            'User Defined Exceptions',
        ],
        'File Handling': [
            'open',
            'File Operations',
            'Context Manager'
        ],
        'Modules and Packages': [
            'The module search path',
            'from import statement',
            'pip package installer'
        ],
        'Python Built-In Modules': [
            'os',
            'sys',
            'glob',
            'shutil',
            'collections',
            'time',
            'calendar',
            'datetime',
            'random',
        ],
        'Python Serialization and Deserialization': [
            'pickle',
            'json',
        ],
        'Threading and Multiprocessing': [
            'Concurrency',
            'Parallelism',
            'thread and threading',
            'Daemon Threads',
            'subprocess',
            'multiprocessing',
        ],
        'GUI Programming': [
            'PyQt5',
            'Tkinter'
        ]
    }
}