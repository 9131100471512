import { DATA as PYTHON_SYLLABUS } from "./Syllabus/python"

export const DATA = {
    'Programming Language': {
        1: {
            name: 'C PROGRAMMING',
            icon: '/assets/images/courses/c.svg',
            sname: 'c_programming',
            syllabus: PYTHON_SYLLABUS,
            type: 1,
        },
        2: {
            name: 'C++ PROGRAMMING',
            icon: '/assets/images/courses/cpp.svg',
            sname: 'cpp_programming',
            syllabus: PYTHON_SYLLABUS,
            type: 1,
        },
        3: {
            name: 'JAVA PROGRAMMING',
            icon: '/assets/images/courses/java.svg',
            sname: 'java_programming',
            syllabus: PYTHON_SYLLABUS,
            type: 1,
        },
        4: {
            name: '.NET PROGRAMMING',
            icon: '/assets/images/courses/dot_net.svg',
            sname: 'dot_net_programming',
            syllabus: PYTHON_SYLLABUS,
            type: 1,
        },
        5: {
            name: 'PYTHON PROGRAMMING',
            icon: '/assets/images/courses/python.svg',
            sname: 'python_programming',
            syllabus: PYTHON_SYLLABUS,
            type: 1,
        },
    },
    'Artificial Intelligence': {
        1: {
            name: 'DATA SCIENCE - I',
            icon: '/assets/images/courses/data_science1.png',
            sname: 'data_science_1',
            syllabus: PYTHON_SYLLABUS,
            type: 2,
        },
        2: {
            name: 'DATA SCIENCE - II',
            icon: '/assets/images/courses/data_science2.png',
            sname: 'data_science_2',
            syllabus: PYTHON_SYLLABUS,
            type: 2,
        },
        3: {
            name: 'COMPUTER VISION',
            icon: '/assets/images/courses/computer_vision.png',
            sname: 'computer_vision',
            syllabus: PYTHON_SYLLABUS,
            type: 2,
        },
        4: {
            name: 'INTERNET OF THINGS',
            icon: '/assets/images/courses/iot.png',
            sname: 'internet_of_things',
            syllabus: PYTHON_SYLLABUS,
            type: 2,
        },
    },
    'Web Development': {
        1: {
            name: 'HTML, CSS AND JS',
            icon: '/assets/images/courses/html.svg',
            sname: 'html_css_and_js',
            syllabus: PYTHON_SYLLABUS,
            type: 3,
        },
        2: {
            name: 'REACT JS',
            icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg',
            sname: 'react_js',
            syllabus: PYTHON_SYLLABUS,
            type: 3,
        },
    },
    'Mobile App Development': {
        1: {
            name: 'ANDROID PROGRAMMING',
            icon: '/assets/images/courses/android.svg',
            sname: 'android_programming',
            syllabus: PYTHON_SYLLABUS,
            type: 4,
        },
    },
    'Designing': {
        1: {
            name: 'GRAPHIC DESIGNING',
            icon: '/assets/images/courses/designing.png',
            sname: 'graphic_designing',
            syllabus: PYTHON_SYLLABUS,
            type: 5,
        },
        2: {
            name: 'ADOBE PREMIERE',
            icon: '/assets/images/courses/premiere.svg',
            sname: 'adobe_premiere',
            syllabus: PYTHON_SYLLABUS,
            type: 5,
        },
        3: {
            name: 'ADOBE AUDITION',
            icon: '/assets/images/courses/audition.svg',
            sname: 'adobe_audition',
            syllabus: PYTHON_SYLLABUS,
            type: 5,
        },
        4: {
            name: 'ADOBE AFTER EFFECTS',
            icon: '/assets/images/courses/after_effects.svg',
            sname: 'adobe_after_effects',
            syllabus: PYTHON_SYLLABUS,
            type: 5,
        },
    },
    'Other': {
        1: {
            name: 'COMPUTER FOUNDATION',
            icon: '/assets/images/courses/computer.png',
            sname: 'computer_foundation',
            syllabus: PYTHON_SYLLABUS,
            type: 6,
        },
        2: {
            name: 'HARDWARE AND NETWORKING',
            icon: '/assets/images/courses/networking.png',
            sname: 'hardware_and_netwroking',
            syllabus: PYTHON_SYLLABUS,
            type: 6,
        },
        3: {
            name: 'TALLY AND TAXATION',
            icon: '/assets/images/courses/tally.png',
            sname: 'tally_and_taxation',
            syllabus: PYTHON_SYLLABUS,
            type: 6,
        },
    }
}
