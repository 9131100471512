import React from 'react';
import { Grid } from '@mui/material';
import Intro from './Intro';
import Project from './Project';

export default function Projects() {
    return (
        <Grid container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'}>
            <Intro />
            <Project />
        </Grid>
    )
}