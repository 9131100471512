import React from "react"
import { Grid, Typography, CardMedia } from "@mui/material"
import { size, COLORS } from "../../utils"
import { DATA } from '../ResearchAssistance/Data'
import ArrowRightIcon from '../../assets/icons/arrowright'

export default function ResearchAssistance() {
    const Data = [
        Object.keys(DATA).slice(0, 2),
        Object.keys(DATA).slice(2, 4),
        Object.keys(DATA).slice(4, 6)
    ]
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'} rowGap={size({ p: 2, h: true })}
            m={`${size({ p: 15, h: true })} 0px`}
        >
            <Grid item>
                <Typography
                    sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 2.2 }),
                        fontWeight: 600,
                        textAlign: 'center',
                        color: `${COLORS.C2}`,
                        padding: `0px ${size({ p: 20 })}`
                    }}
                >
                    End-to-End PhD support from topic selection to journal publication
                </Typography>
            </Grid>
            <Grid item width={size({ p: 60 })}>
                <Typography
                    sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 1 }),
                        fontWeight: 400,
                        textAlign: 'center',
                        color: `${COLORS.C1}A0`,
                    }}
                >
                    Navigate your PhD journey: We empower your research, from topic selection to journal publication.
                </Typography>
            </Grid>
            <Grid item container flexWrap={'nowrap'} alignItems={'center'} justifyContent={'center'}
                width={size({ p: 85 })}>
                {
                    Data.map((dat, i) => {
                        return (
                            <Grid item container flexWrap={'nowrap'} direction={'column'}
                                alignItems={'center'} justifyContent={'center'}>
                                {
                                    dat.map((k) => {
                                        return (
                                            <Grid item container flexWrap={'nowrap'} direction={'column'}
                                                rowGap={size({ p: 0.5, h: true })} sx={{
                                                    padding: `${size({ p: 3, h: true })} ${size({ p: 4 })}`,
                                                }}>
                                                <Grid item container flexWrap={'nowrap'}
                                                    direction={'column'} rowGap={size({ p: 1, h: true })}>
                                                    <Grid item>
                                                        <CardMedia
                                                            component={'img'}
                                                            image={DATA[k].icon}
                                                            sx={{
                                                                width: size({ p: 3 }),
                                                                height: size({ p: 3 }),
                                                            }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography sx={{
                                                            fontFamily: 'Archia',
                                                            fontSize: size({ p: 1.1 }),
                                                            fontWeight: 500,
                                                            color: COLORS.C1
                                                        }}>
                                                            {k}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Typography sx={{
                                                        fontFamily: 'Archia',
                                                        fontSize: size({ p: 0.9 }),
                                                        fontWeight: 400,
                                                        color: COLORS.C1 + 'A0',
                                                        height: size({ p: 14, h: true })
                                                    }}>
                                                        {DATA[k].short_desc.split(" ").slice(0, 25).join(" ") + '...'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item container flexWrap={'nowrap'} columnGap={size({ p: 0.4 })}
                                                    justifyContent={"flex-end"} sx={{
                                                        cursor: 'pointer'
                                                    }}>
                                                    <Grid item>
                                                        <Typography sx={{
                                                            fontFamily: 'Archia',
                                                            fontSize: size({ p: 0.8 }),
                                                            color: COLORS.C1,
                                                            fontWeight: 600,
                                                        }}>
                                                            Learn More
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item mt={size({ p: 0.3, h: true })}>
                                                        <ArrowRightIcon
                                                            width={size({ p: 1 })}
                                                            height={size({ p: 1 })}
                                                            fill={COLORS.C1}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid >
    )
}