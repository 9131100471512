import * as React from "react";

function Round(props) {
    return (
        <svg
            width="204"
            height="204"
            viewBox="0 0 204 204"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute right-0 bottom-0 h-51">
            <defs>
                <filter id="circles-md-glow"><feGaussianBlur in="SourceGraphic" stdDeviation="0.2"></feGaussianBlur>
                </filter>
            </defs>
            <mask id="circles-md-mask" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="205" height="204">
                <path d="M0.5 0H204.5V204H0.5V0Z" fill="url(#circles-md-gradient)"></path>
            </mask>
            <g mask="url(#circles-md-mask)">
                <circle cx="36.5" cy="148" r="40" stroke="#1FDFFF" filter="url(#circles-md-glow)" style={{ stroke: 'rgb(31, 223, 255)' }}></circle>
                <circle cx="92.5" cy="148" r="40" stroke="#0A5F81" filter="url(#circles-md-glow)" style={{ stroke: 'rgb(29, 214, 246)' }}></circle>
                <circle cx="148.5" cy="148" r="40" stroke="#1FDFFF" filter="url(#circles-md-glow)" style={{ stroke: 'rgb(31, 223, 255)' }}></circle>
                <circle cx="204.5" cy="148" r="40" stroke="#0A5F81" filter="url(#circles-md-glow)" style={{ stroke: 'rgb(29, 214, 246)' }}></circle>
            </g >
            <defs><radialGradient id="circles-md-gradient" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(102.5 102) rotate(90) scale(102 81.1808)">
                <stop stop-color="#D9D9D9"></stop>
                <stop offset="1" stop-color="#D9D9D9" stop-opacity="0">
                </stop></radialGradient>
            </defs>
        </svg >
    );
}

export default Round;
