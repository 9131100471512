import * as React from "react";

function AndroidIcon(props) {
    return (
        <svg
            fill="currentColor"
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            {...props}
        >
            <path d="M10.213 1.471l.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 008.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 00-1.673 1.476A3.697 3.697 0 003.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 00-1.664-1.476zM6.22 3.303a.367.367 0 01-.267.11.35.35 0 01-.263-.11.366.366 0 01-.107-.264.37.37 0 01.107-.265.351.351 0 01.263-.11c.103 0 .193.037.267.11a.36.36 0 01.112.265.36.36 0 01-.112.264zm4.101 0a.351.351 0 01-.262.11.366.366 0 01-.268-.11.358.358 0 01-.112-.264c0-.103.037-.191.112-.265a.367.367 0 01.268-.11c.104 0 .19.037.262.11a.367.367 0 01.107.265c0 .102-.035.19-.107.264zM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 00.7.288.973.973 0 00.71-.288.95.95 0 00.292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 00.71.288.973.973 0 00.71-.288.95.95 0 00.292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404zm10.495-6.587a.983.983 0 00-.702.278.91.91 0 00-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 00.702.284c.28 0 .517-.095.712-.284a.924.924 0 00.293-.69V6.146a.91.91 0 00-.293-.685.995.995 0 00-.712-.278zm-12.702.283a.985.985 0 01.712-.283c.273 0 .507.094.702.283a.913.913 0 01.293.68v4.063a.932.932 0 01-.288.69.97.97 0 01-.707.284.986.986 0 01-.712-.284.924.924 0 01-.293-.69V6.146c0-.264.098-.491.293-.68z" />
        </svg>
    );
}

export default AndroidIcon;
