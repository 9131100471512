export const DATA = {
    'Proposal Writing': {
        icon: '/assets/images/research_assistance/proposal.png',
        short_desc: "We recognize the pivotal role of a well-crafted and compelling research proposal as the cornerstone of a successful research endeavor. Our commitment lies in supporting you through every step of this crucial process, ensuring your project stands out and reaches new heights.",
        skills: [
            'Customized Research Proposals',
            'Comprehensive Literature Review',
            'Problem Statement Development',
            'Motivational Factors',
            'Clear Research Objectives',
            'Methodology Design',
        ],
        bgi: '/assets/images/research_assistance/bgi1.svg',
        bgic: '#55FFC6',
    },
    'Implementation': {
        icon: '/assets/images/research_assistance/implementation.png',
        short_desc: "Recognizing the formidable challenge of transitioning from theoretical research to real-world applications, we at Genesys Academy are here to support and guide you through this critical phase. Our commitment is to make this transition seamless, ensuring your research finds practical significance and impact.",
        skills: [
            'Assistance with data collection, annotation, and management.',
            'Dynamic Data Analysis',
            'Real-Time Solutions',
            'Interdisciplinary Approach / Collaboration',
            'User Interface Creation',
            'State-of-the-Art Comparison',
        ],
        bgi: '/assets/images/research_assistance/bgi1.svg',
        bgic: '#55C6FF',
    },
    'Paper Writing': {
        icon: '/assets/images/research_assistance/paper.png',
        short_desc: 'Dedicated to taking your research articles to unprecedented levels of excellence, our team of highly skilled professionals specializes in creating impactful and insightful research articles. Trust us to elevate the quality and impact of your scholarly work.',
        skills: [
            'Highly Skilled Professionals',
            'Focus on Novelty and Original Contributions',
            'High Language Quality',
            'Plagiarism-Free Guarantee using Turnitin software',
            'Timely Delivery',
        ],
        bgi: '/assets/images/research_assistance/bgi1.svg',
        bgic: '#FF55C6',
    },
    'Plagiarism Checking': {
        icon: '/assets/images/research_assistance/plagiarism.png',
        short_desc: 'Emphasizing a strong commitment to academic integrity, we at Genesys Academy recognize the serious consequences of plagiarism. In our efforts to maintain the highest standards, we leverage the powerful Turnitin tool to actively reduce instances of plagiarism, fostering a culture of originality and scholarly honesty."',
        skills: [
            'Plagiarism Detection',
            'Plagiarism Reduce',
            'Comprehensive Reports',
            'Proper Citations to Sources',
        ],
        bgi: '/assets/images/research_assistance/bgi1.svg',
        bgic: '#C6FF55',
    },
    'Thesis Writing': {
        icon: '/assets/images/research_assistance/thesis.png',
        short_desc: 'Navigating the critical milestone of publishing research in renowned international journals can be complex. At Genesys Academy, we are dedicated to simplifying this process, providing support and guidance to ensure your research reaches the global stage and makes a lasting impact.',
        skills: [
            'Plagiarism-Free Theses.',
            'Customizable Self-Plagiarism Removal',
            'High Language Quality ',
            'Extensive References and Citations ',
            'Effective Data Analysis ',
            'Enhanced Figures and Illustrations',
        ],
        bgi: '/assets/images/research_assistance/bgi1.svg',
        bgic: '#C655FF',
    },
    'Journal Publication': {
        icon: '/assets/images/research_assistance/publication.png',
        short_desc: 'Publishing research in renowned international journals is a critical milestone in the research journey, and we are here to simplify this process. We understand that many find research to be a challenging endeavor. Our dedicated team is committed to assisting you in publishing your research articles in esteemed journals, ensuring your work meets all the necessary requirements. We publish articles in prestigious journals such as SCI, SCIE, Scopus, UGC, Anna University Annexure, and Q1 Journals.',
        skills: [
            'Journal Selection ',
            'Manuscript Preparation',
            'Submission Assistance ',
            'Peer-Review Guidance',
            'Journal Comments Revision',
            'Wide Journal Coverage: SCI, SCIE, Scopus, UGC, Anna University Annexure, and Q1 Journals',
        ],
        bgi: '/assets/images/research_assistance/bgi1.svg',
        bgic: '#FFC655',
    },
}
