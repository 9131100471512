import React, { useState } from 'react';
import { Grid, CardMedia, Typography, List, ListItem, ListItemIcon, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { size, COLORS } from '../../utils';
import { DATA } from './Data'
import ReachUs from './ReachUs'
import PatchIcon from '../../assets/icons/patch'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

function RABase(props) {
    const { title, expanded, i, handleChange } = props
    const data = DATA[title]
    const skills = [
        data.skills.slice(0, parseInt(data.skills.length / 2)),
        data.skills.slice(parseInt(data.skills.length / 2), data.skills.length),
    ]
    return (
        <Accordion disableGutters={true} expanded={expanded === i}
            onChange={handleChange(i)} sx={{
                border: 'none',
                boxShadow: 'none',
                backgroundColor: COLORS.WHITE,
                padding: 0,
                margin: 0,
                '&:before': {
                    display: 'none',
                }
            }}>
            <AccordionSummary
                expandIcon={
                    <ExpandCircleDownIcon sx={{
                        color: COLORS.C3,
                        fontSize: size({ p: 2.2 })
                    }} />
                }
                sx={{
                    height: size({ p: 10, h: true }),
                    borderBottom: (
                        (expanded === i) || (i === (Object.keys(DATA).length) - 1)
                    ) ? 'none' : `${size({ p: 0.1 })} solid ${COLORS.C2}50`
                }}>
                <Grid container flexWrap={'nowrap'} columnGap={size({ p: 1 })}
                    alignItems={'center'}>
                    <Grid item sx={{
                        backgroundColor: COLORS.C1,
                        width: size({ p: 3 }),
                        height: size({ p: 3 }),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                    }}>
                        <CardMedia
                            component={'img'}
                            image={DATA[title].icon}
                            sx={{
                                width: size({ p: 1.8 }),
                                height: size({ p: 1.8 }),
                            }} />
                    </Grid>
                    <Grid item>
                        <Typography sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 1.1 }),
                            fontWeight: 600,
                            color: COLORS.C2,
                        }}>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{
                border: 'none',
                borderBottom: (
                    (expanded !== i) || (i === (Object.keys(DATA).length) - 1)
                ) ? 'none' : `${size({ p: 0.1 })} solid ${COLORS.C2}50`
            }}>
                <Grid container flexWrap={'nowrap'} direction={'column'} rowGap={size({ p: 2, h: true })}>
                    <Grid item>
                        <Typography sx={{
                            fontFamily: 'Space Mono',
                            fontWeight: 400,
                            fontSize: size({ p: 0.95 }),
                            color: COLORS.BLACK + 'A0',
                            paddingRight: size({ p: 2 }),
                        }}>
                            {data.short_desc}
                        </Typography>
                    </Grid>
                    <Grid item container flexWrap={'nowrap'} justifyContent={'space-between'}>
                        {
                            skills.map((k) => {
                                return (
                                    <Grid item>
                                        {
                                            <List>
                                                {k.map((p, i) => {
                                                    return (
                                                        <ListItem disablePadding key={`skills_data_${i}`} sx={{
                                                            padding: `${size({ p: 0.1 })} 0px ${size({ p: 0.1 })} 0px`,
                                                        }}>
                                                            <ListItemIcon sx={{
                                                                minWidth: 0
                                                            }}>
                                                                <PatchIcon
                                                                    fill={COLORS.C2}
                                                                    width={size({ p: 1.4 })}
                                                                    height={size({ p: 1.4 })} />                                                            </ListItemIcon>
                                                            <ListItemText>
                                                                <Typography sx={{
                                                                    fontFamily: 'Space Mono',
                                                                    fontSize: size({ p: 0.9 }),
                                                                    fontWeight: 400,
                                                                    color: COLORS.BLACK + 'A0',
                                                                    paddingLeft: size({ p: 0.5 }),
                                                                    paddingRight: size({ p: 4 })
                                                                }}>
                                                                    {p}
                                                                </Typography>
                                                            </ListItemText>
                                                        </ListItem>
                                                    )
                                                })}
                                            </List>
                                        }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}


export function ResearchAssistance() {
    const [expanded, setExpanded] = useState(false)
    const handleChange = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'}>
            <Grid item container flexWrap={'nowrap'} direction={'column'}
                padding={`0px ${size({ p: 15 })}`} mt={`${size({ p: 15, h: true })}`}
                mb={`${size({ p: 15, h: true })}`}>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 2.2 }),
                            fontWeight: 600,
                            textAlign: 'center',
                            color: `${COLORS.C2}`,
                            marginBottom: size({ p: 8, h: true })
                        }}
                    >
                        End-to-End PhD support<br />from topic selection to journal publication
                    </Typography>
                </Grid>
                {
                    Object.keys(DATA).map((k, i) => {
                        return (
                            <RABase title={k} expanded={expanded} i={i} handleChange={handleChange} />
                        )
                    })
                }
            </Grid>

            <ReachUs />
        </Grid>
    )
}