import React from 'react';
import { Grid } from '@mui/material';
import GalleryC from './Gallery'
import Team from './Team'

export default function Gallery() {
    return (
        <Grid container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'}>
            <GalleryC />
            <Team />
        </Grid>
    )
}