import React from 'react';
import { Grid, Box } from '@mui/material';
import Intro from './Intro';
import Career from './Career';

export default function Careers() {
    return (
        <Box>
            <Grid container>
                <Intro />
                <Career />
            </Grid>
        </Box>
    )
}