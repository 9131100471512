import React from "react"
import { Grid, Typography, TextField as TF, Button, Autocomplete } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { size, COLORS } from "../../utils"
import { DATA } from './Data'

const useStyles = makeStyles(theme => ({
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${COLORS.C2} !important`,
        }
    },

    cssFocused: {
        borderColor: `${COLORS.C2} !important`
    },

    notchedOutline: {
        borderWidth: size({ p: 0.08 }),
        borderColor: `${COLORS.C2} !important`
    },
}))

function TextField({ label, tf_props, params }) {
    const cls = useStyles()
    params = (params === undefined) ? {} : params
    return (
        <Grid item container direction={'column'}>
            <Grid item>
                <Typography
                    sx={{
                        fontFamily: 'Space Mono',
                        fontSize: size({ p: 0.9 }),
                        fontWeight: 400,
                        wordSpacing: size({ p: 0.2, neg: true }),
                        marginBottom: size({ p: 0.5 }),
                        color: `${COLORS.C2}E0`,
                    }}
                >{label}</Typography>
            </Grid>
            <Grid item>
                <TF
                    placeholder={label}
                    {...tf_props}
                    {...params}
                    InputProps={{
                        style: {
                            fontFamily: 'Space Mono',
                            color: COLORS.C2,
                            fontSize: size({ p: 0.9 })
                        },
                        classes: {
                            root: cls.cssOutlinedInput,
                            focused: cls.cssFocused,
                            notchedOutline: cls.notchedOutline,
                        },
                        ...params.InputProps,
                    }}
                />
            </Grid>
        </Grid>
    )
}


export default function ReachUs() {
    let SERVICES = []
    for (let z = 0; z < Object.keys(DATA).length; z++) {
        const k = Object.keys(DATA)[z]
        SERVICES = [
            ...SERVICES,
            { label: k }
        ]
    }
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'} width={size({ p: 100 })} height={size({ p: 90, h: true })}
            rowGap={size({ p: 2.5 })} mb={size({ p: 5, h: true })}>
            <Grid item container flexWrap={'nowrap'} alignItems={'center'}
                justifyContent={'center'} columnGap={size({ p: 2 })}>
                <Grid item>
                    <Typography sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 1.8 }),
                        fontWeight: 800,
                        color: COLORS.C2,
                        textAlign: 'center',
                    }}>Get A Free Technical Discussion</Typography>
                    <Typography id={'ra_reach_us'} sx={{
                        fontFamily: 'Space Mono',
                        fontSize: size({ p: 1 }),
                        fontWeight: 400,
                        color: `${COLORS.C2}`,
                        textAlign: 'center',
                        marginTop: size({ p: 0.8, h: true })
                    }}>Fill Out The Form and Submit Now</Typography>
                </Grid>
            </Grid>
            <Grid item container flexWrap={'nowrap'} columnGap={size({ p: 2 })}
                padding={`0px ${size({ p: 20 })}`}>
                <Grid item container flexWrap={'nowrap'} direction={'column'}
                    rowGap={size({ p: 1.5, h: true })}>
                    <Grid item>
                        <TextField label="First Name" tf_props={{
                            fullWidth: 'true'
                        }} />
                    </Grid>
                    <Grid item>
                        <TextField label="Email Address" tf_props={{
                            fullWidth: 'true'
                        }} />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            options={SERVICES}
                            height={size({ p: 8, h: true })}
                            renderInput={
                                (params) => {
                                    console.log(params)
                                    return (
                                        <TextField
                                            params={params}
                                            label={'Service'}
                                        />
                                    )
                                }
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item container flexWrap={'nowrap'} direction={'column'}
                    rowGap={size({ p: 1.5, h: true })}>
                    <Grid item>
                        <TextField label="Last Name" tf_props={{
                            fullWidth: 'true'
                        }} />
                    </Grid>
                    <Grid item>
                        <TextField label="Contact Number" tf_props={{
                            fullWidth: 'true'
                        }} />
                    </Grid>
                    <Grid item>
                        <TextField label="Your Subject Area" tf_props={{
                            fullWidth: 'true'
                        }} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container flexWrap={'nowrap'} alignItems={'center'}
                justifyContent={'center'} padding={`0px ${size({ p: 20 })}`}
                mt={size({ p: 3, h: true, neg: true })}>
                <Grid item width={size({ p: 60 })}>
                    <TextField label="Share Your Thoughts With Us" tf_props={{
                        fullWidth: 'true',
                        multiline: 'true',
                        rows: 5,
                    }} />
                </Grid>
            </Grid>
            <Grid item container flexWrap={'nowrap'} direction={'column'}
                padding={`0px ${size({ p: 35 })}`} rowGap={size({ p: 0.5, h: true })}>
                <Grid item>
                    <Button variant="contained" fullWidth sx={{
                        backgroundColor: `${COLORS.C2}`,
                        fontFamily: 'Space Mono',
                        fontWeight: 400,
                        fontSize: size({ p: 1 }),
                        color: `${COLORS.WHITE}`,
                        textTransform: 'none',
                        ':hover': {
                            backgroundColor: `${COLORS.C2}`,
                        },
                    }}>Submit</Button>
                </Grid>
                <Grid item>
                    <Typography sx={{
                        fontFamily: 'Space Mono',
                        fontSize: size({ p: 0.8 }),
                        fontWeight: 400,
                        textAlign: 'center',
                        color: `${COLORS.BLACK}A0`,
                    }}>Thank you for filling out the form, someone from our team will contact you soon.</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}