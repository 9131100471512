import React from "react";
import { size, COLORS } from "../../utils";
import { Grid, Typography, CardMedia, Button } from "@mui/material"

export default function Intro(props) {
    return (
        <Grid item container flexWrap={'nowrap'} alignItems={'center'} justifyContent={'center'}
            width={size({ p: 100 })} height={size({ p: 90, h: true })} columnGap={size({ p: 1 })}>
            <Grid item container flexWrap={'nowrap'} direction={'column'} width={size({ p: 30 })}
                height={size({ p: 50, h: true })} justifyContent={'center'}
                marginBottom={size({ p: 25, h: true })}>
                <Grid item>
                    <Typography component={'span'}
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 1.4 }),
                            fontWeight: 400,
                            textAlign: 'left',
                            // textTransform: 'uppercase',
                            color: COLORS.C2,
                        }}
                    >
                        Career At Genesys
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography component={'span'}
                        sx={{
                            fontFamily: 'Space Mono',
                            fontSize: size({ p: 3 }),
                            fontWeight: 700,
                            textAlign: 'left',
                            textTransform: 'uppercase',
                            color: COLORS.C2,
                        }}
                    >
                        Work with us
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Space Mono',
                            fontSize: size({ p: 1 }),
                            fontWeight: 400,
                            textAlign: 'left',
                            color: COLORS.BLACK + 'A0',
                        }}
                    >
                        Join our dynamic team, where your expertise is valued, and together, let's shape the future of technology.
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained"
                        onClick={() => {
                            document.getElementById('career_ops').scrollIntoView({
                                behavior: 'smooth', block: 'center'
                            })
                        }}
                        sx={{
                            backgroundColor: `${COLORS.C2}`,
                            fontFamily: 'Archia',
                            fontWeight: 500,
                            fontSize: size({ p: 1 }),
                            ':hover': {
                                backgroundColor: `${COLORS.C2}`,
                            },
                            textTransform: 'none',
                            marginTop: size({ p: 4, h: true })
                        }}>View Careers</Button>
                </Grid>
            </Grid>
            <Grid item height={size({ p: 90, h: true })}>
                <CardMedia
                    component={'img'}
                    image={'/assets/images/careers/careers.svg'}
                    sx={{
                        width: size({ p: 45 }),
                        height: size({ p: 45 }),
                        objectFit: 'contain'
                    }} />
            </Grid>
        </Grid>
    )
}
