import React from "react"
import { Grid, Typography } from "@mui/material"
import { size, COLORS } from "../../utils"

export default function Success() {
    const DATA = {
        1: { count: '8+', text: 'Years Experience' },
        2: { count: '5.2K+', text: 'Students Certified' },
        3: { count: '3.5K+', text: 'Projects Delivered' },
        4: { count: '10K+', text: 'Papers Published' },
        5: { count: '100+', text: 'Full PHD Assitance' },
    }
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} justifyContent={'center'}
            alignItems={'center'} width={size({ p: 75 })} rowGap={size({ p: 2, h: true })}
            mt={size({ p: 15, h: true })}
            height={size({ p: 30, h: true })} sx={{
                background: `radial-gradient(63.24% 50% at 50% 0%,rgba(255,255,255,.48) 0%,rgba(255,255,255,0) 100%),${COLORS.C1}`,
                backgroundBlendMode: 'overlay,normal',
                padding: `0px ${size({ p: 2 })}`,
                borderRadius: size({ p: 1.5 })
            }}>
            <Grid item>
                <Typography
                    sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 2 }),
                        fontWeight: 600,
                        color: COLORS.WHITE,
                    }}
                >
                    What we achieved so far?
                </Typography>
            </Grid>
            <Grid item container flexWrap={'nowrap'}>
                {Object.keys(DATA).map((k, i) => {
                    return (
                        <Grid item container flexWrap={'nowrap'} direction={'column'}
                            pl={size({ p: 2 })}>
                            <Grid item>
                                <Typography sx={{
                                    fontFamily: 'Archia',
                                    fontSize: size({ p: 2.3 }),
                                    fontWeight: 600,
                                    color: COLORS.WHITE + 'E0'
                                }}>
                                    {DATA[k].count}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    sx={{
                                        fontFamily: 'Space Mono',
                                        fontSize: size({ p: 0.9 }),
                                        fontWeight: 400,
                                        color: COLORS.WHITE + 'A0'
                                    }}
                                >
                                    {DATA[k].text}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid >
    )
}
