import React from "react"
import {
    AppBar, CssBaseline, Toolbar, useScrollTrigger,
    Grid, CardMedia, Typography
} from "@mui/material"
import { PropTypes } from "prop-types";
import { useLocation, Link } from "react-router-dom";
import { COLORS, size } from '../utils'

const DATA = {
    logo: '/assets/images/logo.svg',
    menus: {
        1: { name: 'Home', path: '/' },
        2: { name: 'Courses', path: '/courses' },
        3: { name: 'Research Assistance', path: '/research_assistance' },
        4: { name: 'Projects', path: '/projects' },
        5: { name: 'Workshops', path: '/workshops' },
        6: { name: 'Careers', path: '/careers' },
        7: { name: 'Gallery', path: '/gallery' },
        8: { name: 'Contact Us', path: '/contact_us' },
    },
}

function ElevationScroll(props) {
    const { children, trigger } = props;
    return React.cloneElement(children, {
        sx: {
            backgroundColor: 'transparent',
            pt: size({ p: 0.5, h: true }),
            pb: size({ p: 0.5, h: true }),
        },
        elevation: trigger ? 0 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
};


export default function Header(props) {
    const { window } = props
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    })
    const location = useLocation();

    return (
        <ElevationScroll {...props} trigger={trigger}>
            <AppBar position="static">
                <CssBaseline />
                <Toolbar>
                    <Grid container flexWrap={'nowrap'} md alignItems={'center'}
                        justifyContent={'space-evenly'}>
                        <Link to={DATA.menus[1].path} style={{
                            textDecoration: "none",
                            cursor: 'pointer',
                        }}>
                            <Grid item container flexWrap={'nowrap'} alignItems={'center'}
                                columnGap={size({ p: 0.3 })}>
                                <Grid item>
                                    <CardMedia
                                        component={'img'}
                                        image={DATA.logo}
                                        sx={{
                                            width: size({ p: 2.8 }),
                                            height: size({ p: 2.8 }),
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography style={{
                                        fontFamily: 'Archia',
                                        fontWeight: 600,
                                        fontSize: size({ p: 1.8 }),
                                        color: COLORS.C1,
                                        textTransform: 'lowercase',
                                        paddingBottom: size({ p: 0.6, h: true })
                                    }}>
                                        GENESYS ACADEMY
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Link>
                        <Grid item display={'flex'} flexWrap={'nowrap'} columnGap={size({ p: 0.5 })}>
                            {Object.keys(DATA.menus).map((mi) => {
                                return (
                                    <Grid item>
                                        <Link to={DATA.menus[mi].path} style={{
                                            textDecoration: "none",
                                            cursor: 'pointer',
                                        }}>
                                            <Typography sx={{
                                                fontFamily: 'Archia',
                                                fontWeight: 600,
                                                fontSize: size({ p: 0.9 }),
                                                color: COLORS.C1,
                                                padding: `${size({ p: 0.5, h: true })} ${size({ p: 0.8 })}`,
                                                borderRadius: size({ p: 2 }),
                                                backgroundColor: (
                                                    location.pathname.toLowerCase().includes(DATA.menus[mi].name.toLowerCase())
                                                    ||
                                                    (location.pathname === DATA.menus[mi].path)
                                                ) ? COLORS.C3 + 'A0' : 'none',
                                                '&:hover': {
                                                    backgroundColor: COLORS.C3 + '30'
                                                },
                                            }}>
                                                {DATA.menus[mi].name}
                                            </Typography>
                                        </Link>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar >
        </ElevationScroll>
    )
}