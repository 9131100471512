import React from "react";
import { size, COLORS } from "../../utils";
import { Grid, Box, Typography } from "@mui/material"
import workshop from '../../assets/lottiefiles/workshop.json'
import Lottie from 'react-lottie';

export default function Intro() {
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'} width={size({ p: 70 })} height={size({ p: 100, h: true })}
            mt={size({ p: 5, h: true })}>
            <Grid item container flexWrap={'nowrap'} direction={'column'}
                rowGap={size({ p: 1, h: true })}>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 2.2 }),
                            fontWeight: 600,
                            textAlign: 'center',
                            color: `${COLORS.C2}`,
                            padding: `0px ${size({ p: 10 })}`
                        }}
                    >
                        Dive into the future of tech at our workshop -innovation awaits your fingertips!
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 1 }),
                            fontWeight: 400,
                            textAlign: 'center',
                            color: `${COLORS.C1}A0`,
                            padding: `0px ${size({ p: 0 })}`
                        }}
                    >
                        Embark on a journey of skill enhancement with our diverse workshops, carefully designed to cater to the ever-evolving demands of the tech landscape. From cutting-edge technologies to hands-on training, our workshops provide a dynamic learning experience. Join us to stay ahead of the curve and transform your knowledge into practical expertise.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item mt={size({ p: 15, h: true, neg: true })}>
                <Lottie width={size({ p: 45 })} height={size({ p: 45 })}
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: workshop,
                    }} />
            </Grid>
        </Grid >
    )
}

export function Intro1() {
    return (
        <Grid item container flexWrap={'nowrap'} columnGap={size({ p: 1 })}
            alignItems={'center'} justifyContent={'center'} height={size({ p: 84, h: true })}
            sx={{
                backgroundColor: COLORS.C1,
                backgroundImage: `
                linear-gradient(${COLORS.C9} ${size({ p: 0.06 })}, transparent ${size({ p: 0.06 })}), 
                linear-gradient(to right, ${COLORS.C9} ${size({ p: 0.06 })}, ${COLORS.C1} ${size({ p: 0.06 })})
                `,
                backgroundSize: `${size({ p: 2.43 })} ${size({ p: 2.565 })}`,
            }}>
            <Grid item width={size({ p: 48 })} height={size({ p: 84, h: true })}>
                <Lottie width={size({ p: 40 })} height={size({ p: 40 })}
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: workshop,
                    }} />
            </Grid>
            <Grid item container width={size({ p: 42 })} justifyContent={'flex-end'}>
                <Grid item>
                    <Typography component={'span'}
                        sx={{
                            fontFamily: 'Space Mono',
                            fontSize: size({ p: 4.5 }),
                            fontWeight: 700,
                            textAlign: 'left',
                            textTransform: 'uppercase',
                            lineHeight: size({ p: 10.5, h: true }),
                            color: `${COLORS.C9}E0`,
                            backgroundColor: COLORS.C1,
                        }}
                    >
                        Dive into the
                        <Box component={'span'} sx={{
                            color: COLORS.C10,
                        }}> future </Box>of tech at our workshop<br />
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography component={'span'}
                        sx={{
                            fontFamily: 'Space Mono',
                            fontSize: size({ p: 1.2 }),
                            fontWeight: 400,
                            textAlign: 'right',
                            color: COLORS.C2 + 'E0',
                            lineHeight: size({ p: 10.5, h: true }),
                            backgroundColor: COLORS.C1,
                        }}
                    >
                        -innovation awaits your fingertips!
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
