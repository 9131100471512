import React from "react";
import { size, COLORS } from "../../utils";
import { Grid, Typography } from "@mui/material"
import { DATA } from './Data'
import Tabs from "./Tabs";

export default function Course() {
    let ALL_DATA = {

    }
    Object.keys(DATA).map((k1) => {
        return (
            Object.keys(DATA[k1]).map((k2) => {
                return (
                    ALL_DATA[Object.keys(ALL_DATA).length + 1] = DATA[k1][k2]
                )
            })
        )
    })
    const NEW_DATA = {
        'All': ALL_DATA,
        ...DATA,
    }
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'} width={size({ p: 100 })} height={size({ p: 80, h: true })}
            rowGap={size({ p: 0.5, h: true })}>
            <Grid item>
                <Typography sx={{
                    fontFamily: 'Archia',
                    fontSize: size({ p: 1.4 }),
                    fontWeight: 400,
                    color: COLORS.C2,
                }}>
                    Start Learning
                </Typography>
            </Grid>
            <Grid item>
                <Typography sx={{
                    fontFamily: 'Archia',
                    fontSize: size({ p: 2.2 }),
                    fontWeight: 600,
                    color: COLORS.C2,
                    textTransform: 'uppercase'
                }}>
                    Certified Courses
                </Typography>
            </Grid>
            <Tabs data={NEW_DATA} />
        </Grid>
    )
}
