export const DATA = {
    'Skill level': 'Beginner',
    'Certificate': 'Included',
    Prerequesties: 'Python 3',
    description: 'Machine Learning Data Scientists solve problems at scale, make predictions, find patterns, and more! They use Python, SQL, and algorithms.',
    about: 'Companies are looking for data-driven decision makers, and this Career Path will teach you the skills you need to become just that. You’ll learn to analyze data, communicate your findings, draw predictions with machine learning, and even build neural networks. Along the way, you’ll build portfolio-worthy projects that will help you get job-ready. ',
    skills: [
        'Knowledge about statistics and ml algorithms',
        'ML frameworks in python',
        'Solving ML problems at your own'
    ],
    reviews: {
        1: {
            name: 'Kumar.K',
            review: "I really enjoyed this course. The most difficult parts were succinctly explained in detail and had witty banter thrown in so the tasks weren't too serious. The video walkthroughs are really useful and everything is clarified really well. I highly recommend this course if you want to learn the essentials of Machine Learning!",
        },
        2: {
            name: 'Arjun M',
            review: "This is one of the best ML courses I've ever taken. The assignments and projects make a significant difference in learning programming. The course is well-explained, with exercises that review what you have learned.",
        },
        3: {
            name: 'Sofia B',
            review: "These courses are excellent for beginners! They have the perfect balance of theory, practice examples, and hands-on projects. It genuinely makes learning these complex topics easy and fun. I honestly can't think of a single critique. Thank you Genesys!",
        }
    },
    modules: {
        1: {
            Morning: [
                'Introduction to Machine Learning',
                'Types Of Machine Learning',
                'How Machine Learning Algorithms Work?',
            ],
            AfterNoon: [
                'Introduction to Python',
                'Installation',
                'Python IDLE',
                'Pycharm IDE',
                'Basic Syntax',
                'Identifiers',
                'Keywords',
                'Indentation',
            ]
        },
        2: {
            Morning: [
                'Data Types ',
                'Numbers',
                'String',
                'List',
                'Object Oriented Programming',
                'Class and Objects',
                'Inheritance',
                'Method Overriding',
                'File Handling',
                'Method Overloading',
                'Special Methods (or) Dunder Methods',
            ],
            AfterNoon: [
                'Activity -> Sober Walk',
                'Activity -> Snail Sort',
                'Activity -> Snail Crawls Up',
                'Activity -> Billiards Triangle',
                'Activity -> Next Biggest Number',
                'Activity -> Next Smallest Number',
                'Activity -> Mumbling',
                'Activity -> Stone Piles',
            ]
        },
        3: {
            Morning: [
                'Machine Learning Life Cycle',
                'Variables',
                'Regression Analysis -> Linear Regression Single Variable',
                'Regression Analysis -> Linear Regression Multiple Variable',
            ],
            AfterNoon: [
                'Capstone Project -> Sample',
                'Capstone Project -> AutoMPG',
                'Capstone Project -> Tesla Stock',
                'Capstone Project -> Rain Tomorrow',
            ]
        },
        4: {
            Morning: [
                'Classification -> Logistic Regression Binary Class',
                'Classification -> Logistic Regression Multi Class',
            ],
            AfterNoon: [
                'Capstone Project -> Diabetes',
                'Capstone Project -> Iris',
                'Capstone Project -> Heart Disease',
                'Capstone Project -> Diabetic Retinopathy',
            ]
        },
        5: {
            Morning: [
                'Clustering -> KMeans Clustering',
                'Distance Metrics',
            ],
            AfterNoon: [
                'Capstone Project -> Diabetes',
                'Capstone Project -> Iris',
                'Capstone Project -> Heart Disease',
                'Capstone Project -> Diabetic Retinopathy',
            ]
        }
    }
}