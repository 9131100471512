import React from "react";
import { size, COLORS } from "../../utils";
import { Grid, Typography } from "@mui/material"
import { DATA as Data } from './Data'
import Tabs from "./Tabs";

export default function Workshop() {
    let DATA = {}
    for (let z = 0; z < Object.keys(Data).length; z++) {
        const k = Object.keys(Data)[z]
        if (k !== 'ASSOCIATED')
            DATA[k] = Data[k]
    }
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'} width={size({ p: 100 })} height={size({ p: 80, h: true })}
            rowGap={size({ p: 0.5, h: true })}>
            <Grid item>
                <Typography sx={{
                    fontFamily: 'Archia',
                    fontSize: size({ p: 1.4 }),
                    fontWeight: 400,
                    color: COLORS.C4,
                }}>
                    Start Learning
                </Typography>
            </Grid>
            <Grid item>
                <Typography sx={{
                    fontFamily: 'Archia',
                    fontSize: size({ p: 2.2 }),
                    fontWeight: 600,
                    color: COLORS.C1,
                    textTransform: 'uppercase'
                }}>
                    Workshops With Certification
                </Typography>
            </Grid>
            <Tabs data={DATA} />
        </Grid>
    )
}
