import { styled } from '@mui/material/styles';
import { Tab as MuiTab } from '@mui/material';
import { size, COLORS } from '../../utils';

const Tab = styled(MuiTab)({
    '&&.MuiTab-root': {
        fontFamily: 'Archia',
        color: COLORS.C2,
        fontWeight: 500,
        fontSize: size({ p: 1 }),
        textTransform: 'none',
        padding: `${size({ p: 2, h: true })} ${size({ p: 1 })}`,
    },
    '&&.MuiTab-root:hover': {
        backgroundColor: '#00000010',
    },
    '&&.Mui-selected:hover': {
        backgroundColor: '#00000010',
    },
});

export default Tab;