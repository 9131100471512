import React from "react"
import { Grid, Typography } from "@mui/material"
import { size, COLORS } from '../utils'
import GmailIcon from "../assets/icons/gmail"
import PhoneIcon from "../assets/icons/phone"
import MobileIcon from "../assets/icons/mobile"
import FacebookIcon from "../assets/icons/facebook"
import TwitterIcon from "../assets/icons/twitter"
import LinkedInIcon from "../assets/icons/linkedin"


function Left() {
    const FOLLOW = [
        FacebookIcon, TwitterIcon, LinkedInIcon
    ]
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'}
            rowGap={size({ p: 3, h: true })}>
            <Grid item container flexWrap={'nowrap'} direction={'column'}
                rowGap={size({ p: 0.8, h: true })}>
                <Grid item>
                    <Typography sx={{
                        fontFamily: 'Archia',
                        fontWeight: 600,
                        fontSize: size({ p: 1.2 }),
                        color: COLORS.C1,
                        textTransform: 'lowercase',
                    }}>GENESYS ACADEMY</Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 0.95 }),
                        fontWeight: 400,
                        color: COLORS.C1 + 'A0',
                        whiteSpace: 'nowrap'
                    }}>
                        Copyright © 2023. All rights reserved
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container flexWrap={'nowrap'} direction={'column'}
                rowGap={size({ p: 1, h: true })}>
                <Grid item>
                    <Typography sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 1 }),
                        fontWeight: 600,
                        color: COLORS.C1,
                    }}>Follow Us</Typography>
                </Grid>
                <Grid item container columnGap={size({ p: 0.8 })}>
                    {
                        FOLLOW.map((k, i) => {
                            const Icon = k
                            return (
                                <Grid item>
                                    <Icon
                                        width={size({ p: 2 })}
                                        height={size({ p: 2 })}
                                        fill={COLORS.C1 + 'A0'} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Grid >
    )
}

function Center() {
    const DATA = {
        'Services': {
            'Proposal Writing': '/',
            'Implementation': '/',
            'Article / Paper Writing': '/',
            'Plagarism Reduction': '/',
            'Thesis Writing': '/',
            'Journal Publication': '/',
            'Programming Courses': '/',
            'Internships': '/',
            'Workshops': '/',
        },
        'Legal': {
            'Privacy policy': '/',
            'Terms & Conditions': '/',
        },
    }
    return (
        <Grid item container flexWrap={'nowrap'} columnGap={size({ p: 5 })}>
            {
                Object.keys(DATA).map((k1) => {
                    return (
                        <Grid item container flexWrap={'nowrap'} direction={'column'}
                            rowGap={size({ p: 0.7, h: true })}>
                            <Grid item>
                                <Typography
                                    sx={{
                                        fontFamily: 'Archia',
                                        fontSize: size({ p: 1 }),
                                        fontWeight: 600,
                                        color: COLORS.C1,
                                    }}
                                >
                                    {k1}
                                </Typography>
                            </Grid>
                            <Grid item container direction={'column'} rowGap={size({ p: 1, h: true })}>
                                {Object.keys(DATA[k1]).map((k2) => {
                                    return (
                                        <Grid item>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Archia',
                                                    fontSize: size({ p: .9 }),
                                                    fontWeight: 400,
                                                    cursor: 'pointer',
                                                    color: COLORS.C1 + 'A0',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {k2}
                                            </Typography>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

function Right() {
    const CONTACT = [
        { 'text': 'genesysacademy.cs@gmail.com', icon: GmailIcon },
        { 'text': '0413-2221227', icon: PhoneIcon },
        { 'text': '9677583754', icon: MobileIcon },
        { 'text': '9655395922', icon: MobileIcon },
    ]
    return (
        <Grid item container rowGap={size({ p: 2, h: true })}>
            <Grid item container flexWrap={'nowrap'} direction={'column'}
                rowGap={size({ p: 1, h: true })}>
                <Grid item rowGap={size({ p: 1, h: true })}>
                    <Typography sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 1 }),
                        fontWeight: 600,
                        color: COLORS.C1,
                    }}>Contact</Typography>
                </Grid>
                {
                    CONTACT.map((k) => {
                        const Icon = k.icon
                        return (
                            <Grid item container flexWrap={'nowrap'} alignItems={'center'}
                                columnGap={size({ p: 0.5 })}>
                                <Grid item>
                                    <Icon
                                        width={size({ p: 1.4 })}
                                        height={size({ p: 1.4 })}
                                        fill={COLORS.C1 + 'A0'} />
                                </Grid>
                                <Grid item>
                                    <Typography sx={{
                                        fontFamily: 'Archia',
                                        fontSize: size({ p: .9 }),
                                        fontWeight: 400,
                                        cursor: 'pointer',
                                        color: COLORS.C1 + 'A0',
                                    }}>
                                        {k.text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid item container flexWrap={'nowrap'} direction={'column'}
                rowGap={size({ p: 0.8, h: true })}>
                <Grid item>
                    <Typography sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 1 }),
                        fontWeight: 600,
                        color: COLORS.C1,
                    }}>Address</Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: .9 }),
                        fontWeight: 400,
                        color: COLORS.C1 + 'A0',
                    }}>No.100, New Shopping Complex,<br />
                        2nd Floor, Genesys Academy,<br />
                        Jawaharlal Nehru Street,<br />
                        Puducherry 605001.</Typography>
                </Grid>
            </Grid>
        </Grid >
    )
}


export default function Footer() {
    return (
        <Grid component={'footer'} container flexWrap={'nowrap'}
            justifyContent={'center'} width={size({ p: 100 })}
            padding={`0px ${size({ p: 10 })}`} columnGap={size({ p: 9 })}
            margin={`${size({ p: 20, h: true })} 0px`}>
            <Left />
            <Center />
            <Right />
        </Grid >
    )
}
