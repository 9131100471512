import React from "react";
import { Grid, Box, Typography, TextField as TF, Button } from "@mui/material"
import Lottie from 'react-lottie';
import contact from '../../assets/lottiefiles/contact_us.json'
import { makeStyles } from "@mui/styles";
import { size, COLORS } from "../../utils";

const useStyles = makeStyles(theme => ({
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${COLORS.C1}E0 !important`,
        }
    },

    cssFocused: {
        borderColor: `${COLORS.C1}E0 !important`
    },

    notchedOutline: {
        borderWidth: size({ p: 0.08 }),
        borderColor: `${COLORS.C1} !important`
    },
}))

function TextField({ label, tf_props }) {
    const classes = useStyles()
    return (
        <Grid item container direction={'column'}>
            <Grid item>
                <Typography
                    sx={{
                        fontFamily: 'Space Mono',
                        fontSize: size({ p: 1 }),
                        fontWeight: 400,
                        wordSpacing: size({ p: 0.2, neg: true }),
                        marginBottom: size({ p: 0.5 }),
                        color: COLORS.C1,
                    }}
                >{label}</Typography>
            </Grid>
            <Grid item>
                <TF
                    placeholder={label}
                    {...tf_props}
                    InputProps={{
                        style: {
                            fontFamily: 'Space Mono',
                            fontSize: size({ p: 0.8 }),
                            fontWeight: 400,
                            color: COLORS.C1,
                        },
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default function EnquireForm() {
    return (
        <Grid container flexWrap={'nowrap'} alignItems={'center'}
            justifyContent={'start'} alignContent={'center'} width={size({ p: 100 })}
            height={size({ p: 92, h: true })}>
            <Grid item width={size({ p: 50 })}>
                <Lottie width={size({ p: 50 })} height={size({ p: 50 })} options={{
                    loop: true,
                    autoplay: true,
                    animationData: contact,
                }} />
            </Grid>
            <Grid item container flexWrap={'nowrap'} direction={'column'} width={size({ p: 40 })}
                justifyContent={'center'} rowGap={size({ p: 4, h: true })}>
                <Grid item container direction={'column'}>
                    <Grid item>
                        <Typography
                            sx={{
                                fontFamily: 'Space Mono',
                                fontSize: size({ p: 2.5 }),
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                color: `${COLORS.C1}`,
                                wordSpacing: size({ p: 0.2, neg: true }),
                                letterSpacing: size({ p: 0.1, neg: true }),
                            }}
                        >
                            We'd love to
                            <Box component='span' sx={{
                                color: `${COLORS.C3}`,
                            }}>{' help you'}</Box>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{
                            color: `${COLORS.C1}`,
                            fontSize: size({ p: 1.1 }),
                            fontFamily: 'Space Mono',
                            textTransform: 'none',
                            fontWeight: 400,
                        }}>{"Reach out and we'll get in touch within 24 hours."}</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction={'column'} rowGap={size({ p: 1.5, h: true })}>
                    <Grid item container flexWrap={'nowrap'} columnGap={size({ p: 1 })}>
                        <TextField label="Full Name" tf_props={{
                            fullWidth: 'true'
                        }} />
                        <TextField label="Mobile Number" tf_props={{
                            fullWidth: 'true'
                        }} />
                    </Grid>
                    <Grid item>
                        <TextField label="Email Address" tf_props={{
                            fullWidth: 'true'
                        }} />
                    </Grid>
                    <Grid item>
                        <TextField label="Message" tf_props={{
                            multiline: 'true',
                            fullWidth: 'true',
                            rows: 5,
                            placeholder: 'Leave us a message...',
                        }} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Button variant="contained" fullWidth sx={{
                        backgroundColor: `${COLORS.C2}E0`,
                        fontFamily: 'Space Mono',
                        fontWeight: 400,
                        fontSize: size({ p: 1.3 }),
                        color: `${COLORS.WHITE}`,
                        textTransform: 'none',
                        ':hover': {
                            backgroundColor: `${COLORS.C2}E0`,
                        },
                    }}>Send Message</Button>
                </Grid>
            </Grid>
        </Grid >
    )
}
