import React from "react"
import { Grid, Typography, CardMedia } from "@mui/material"
import { size, COLORS } from "../../utils"


const DATA = [
    { 'Name': 'Dr.J.UthayaKumar', 'Position': 'Founder', 'Image': '/assets/images/gallery/man.svg' },
    { 'Name': 'Dr.A.Sariga UthayaKumar', 'Position': 'Co-Founder', 'Image': '/assets/images/gallery/woman.svg' },
    { 'Name': 'Mr.V.Natarajan', 'Position': 'Senior Graphic Designer', 'Image': '/assets/images/gallery/man.svg' },
    { 'Name': 'Mr.J.RaviKumar', 'Position': 'Senior Python Developer', 'Image': '/assets/images/gallery/man.svg' },
    { 'Name': 'Miss.Tharaniga Ramachandran', 'Position': 'Senior Content Writer', 'Image': '/assets/images/gallery/woman.svg' },
    { 'Name': 'Miss.A.Shakila', 'Position': 'Senior Content Writer', 'Image': '/assets/images/gallery/woman.svg' },
    { 'Name': 'Miss.Jejitha Parakkal', 'Position': 'Publication Coordinator', 'Image': '/assets/images/gallery/woman.svg' },
    { 'Name': 'Mr.ArulPandi', 'Position': 'Python Developer', 'Image': '/assets/images/gallery/man.svg' },
    { 'Name': 'Mrs.Semmozhi', 'Position': 'Content Writer', 'Image': '/assets/images/gallery/woman.svg' },
    { 'Name': 'Mrs.Vijayalakshmi', 'Position': 'Content Writer', 'Image': '/assets/images/gallery/woman.svg' },
    { 'Name': 'Mr.Dinesh Babu', 'Position': 'Graphic Designer', 'Image': '/assets/images/gallery/man.svg' },
]


export default function Team() {
    return (
        <Grid item container flexWrap={'nowrap'} direction={"column"} alignItems={'center'}
            mt={size({ p: 16, h: true })} rowGap={size({ p: 10, h: true })}>
            <Grid item container flexWrap={'nowrap'} direction={"column"} alignItems={'center'}
                rowGap={size({ p: 2, h: true })}>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 2.2 }),
                            fontWeight: 600,
                            textAlign: 'center',
                            color: `${COLORS.C2}`,
                        }}
                    >
                        Meet the team behind your next project_
                    </Typography>
                </Grid>
                <Grid item width={size({ p: 60 })}>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 0.9 }),
                            fontWeight: 500,
                            textAlign: 'center',
                            color: `${COLORS.C1}A0`,
                            padding: `0px ${size({ p: 7 })}`
                        }}
                    >
                        We are a remote team of product designers, laser focused on delivering top tier SaaS product design to world’s most innovative startups.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container alignItems={'center'}
                rowGap={size({ p: 5, h: true })} padding={`0px ${size({ p: 15 })}`}>
                {
                    DATA.map((dat) => {
                        return (
                            <Grid item container flexWrap={'nowrap'} alignItems={'center'}
                                justifyContent={'center'} direction={'column'}
                                rowGap={size({ p: 1, h: true })} xs={3}>
                                <Grid item sx={{
                                    backgroundColor: COLORS.BLACK + 'A0',
                                    borderRadius: size({ p: 1 }),
                                    width: size({ p: 14 }),
                                    height: size({ p: 14 }),
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <CardMedia
                                        component={'img'}
                                        image={dat['Image']}
                                        sx={{
                                            width: size({ p: 10 }),
                                            height: size({ p: 10 }),
                                        }} />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Archia',
                                            fontSize: size({ p: 1 }),
                                            fontWeight: 600,
                                            textAlign: 'left',
                                            color: COLORS.C1,
                                        }}
                                    >
                                        {dat['Name']}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Archia',
                                            fontSize: size({ p: 1 }),
                                            fontWeight: 400,
                                            textAlign: 'left',
                                            color: COLORS.BLACK + 'A0',
                                        }}
                                    >
                                        {dat['Position']}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}
