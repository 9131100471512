import * as React from "react";

function GmailIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props}>
            <path
                d="M18.73 5.41l-1.28 1L12 10.46 6.55 6.37l-1.28-1A2 2 0 002 7.05v11.59A1.36 1.36 0 003.36 20h3.19v-7.72L12 16.37l5.45-4.09V20h3.19A1.36 1.36 0 0022 18.64V7.05a2 2 0 00-3.27-1.64z" />
        </svg>
    );
}

export default GmailIcon;