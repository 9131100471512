import React, { useState, useEffect } from "react"
import { Box, Grid, Typography } from "@mui/material"
import CodeEditor from '@uiw/react-textarea-code-editor';
import Lottie from 'react-lottie';
import design from '../../assets/lottiefiles/design.json';
import WindowsIcon from '../../assets/icons/windows'
import LinuxIcon from '../../assets/icons/linux'
import AppleIcon from '../../assets/icons/apple'
import AndroidIcon from '../../assets/icons/android'
import HardwareIcon from '../../assets/icons/hardware'
import WordIcon from '../../assets/icons/word'
import PowerPointIcon from '../../assets/icons/powerpoint'
import ExcelIcon from '../../assets/icons/excel'
import CIcon from '../../assets/icons/c'
import CPPIcon from '../../assets/icons/cpp'
import DotnetIcon from '../../assets/icons/dotnet'
import CSharpIcon from '../../assets/icons/csharp'
import JavaIcon from '../../assets/icons/java'
import PythonIcon from '../../assets/icons/python'
import HtmlIcon from '../../assets/icons/html'
import CssIcon from '../../assets/icons/css'
import JsIcon from '../../assets/icons/js'
import DjangoIcon from '../../assets/icons/django'
import FlaskIcon from '../../assets/icons/flask'
import ReactIcon from '../../assets/icons/react'
import VSCodeIcon from '../../assets/icons/vscode'
import PycharmIcon from '../../assets/icons/pycharm'
import JupyterIcon from '../../assets/icons/jupyter'
import AnacondaIcon from '../../assets/icons/anaconda'
import AndroidStudioIcon from '../../assets/icons/androidstudio'
import XDIcon from '../../assets/icons/xd'
import PhotoshopIcon from '../../assets/icons/photoshop'
import IllustratorIcon from '../../assets/icons/illustrator'
import AfterEffectsIcon from '../../assets/icons/aftereffects'
import PremiereProIcon from '../../assets/icons/premierepro'
import { size } from '../../utils'
import Carousel from "react-material-ui-carousel";
import PlayIcon from '../../assets/icons/play'
import PauseIcon from '../../assets/icons/pause'
import { COLORS } from '../../utils'

const DATA = {
    line_s: size({ p: 0.06 }),
    box_s: size({ p: 10, h: true }),
    Paper1: {
        CODING: {
            c: `#include <stdio.h>
int main()
{
    printf("Hello, World!");
    return 0;
}`,
            cpp: `using namespace std;
int main()
{
    cout<<"Hello, World!";
    return 0;
}`,
            java: `public class Main {
    public static void main(String[] args) {
        System.out.println("Hello, World!");
    }
}`,
            csharp: `using System;
class Hello {         
    static void Main(string[] args)
    {
        Console.WriteLine("Hello, World!");
    }
}`,
            python: `print("Hello, World!")`,
            javascript: `console.log("Hello, World!")`,
            kotlin: `fun main(args: Array<String>) {
    println("Hello, World!")
}`,
            go: `package main
import "fmt"
func main() {
    fmt.Println("Hello, World!")
}`,
            swift: `print("Hello, World!")`,
            dart: `void main() {
  print("Hello, World!");
}`
        }
    },
    Paper2: {
        design_svg: '/assets/images/home/design.svg'
    },
    Paper3: {
        icons: [
            [
                WindowsIcon, LinuxIcon, AppleIcon, HardwareIcon, AndroidIcon, WordIcon,
                ExcelIcon, PowerPointIcon, CIcon, CPPIcon
            ],
            [
                DotnetIcon, CSharpIcon, JavaIcon, PythonIcon, HtmlIcon,
                CssIcon, JsIcon, DjangoIcon, FlaskIcon, ReactIcon
            ],
            [
                VSCodeIcon, PycharmIcon, JupyterIcon, AnacondaIcon, AndroidStudioIcon, XDIcon,
                PhotoshopIcon, IllustratorIcon, AfterEffectsIcon, PremiereProIcon
            ],
        ],
    },
}

function ToolBarIcon(props) {
    return (
        <Box sx={{
            width: size({ p: 1 }),
            height: size({ p: 1 }),
            backgroundColor: props.color,
            borderRadius: size({ p: 50 }),
            marginRight: size({ p: 0.3 }),
        }} />
    )
}

function Paper1() {
    const code_keys = Object.keys(DATA.Paper1.CODING)
    const [cc, setCC] = useState(0)
    const [ci, setCI] = useState(0)
    const [text, setText] = useState('')

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    useEffect(() => {
        sleep(50).then(() => {
            setCI(ci + 1)
            setText(DATA.Paper1.CODING[code_keys[cc]].slice(0, ci))
            if (ci === DATA.Paper1.CODING[code_keys[cc]].length) {
                setCI(0)
                setCC(cc + 1)
                setText('')
            }
            if (cc === (code_keys.length - 1)) {
                setCC(0)
            }
        })
    })

    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'} alignContent={'center'} rowGap={size({ p: 2, h: true })}
            height={size({ p: 80, h: true })}
        >
            <Grid item>
                <Typography
                    sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 2.2 }),
                        fontWeight: 600,
                        textAlign: 'center',
                        color: `${COLORS.C2}`,
                    }}
                >
                    {'Learn practical skills in <programming/>'} <br /> to build impactful applications and launch your tech career
                </Typography>
            </Grid>
            <Grid item width={size({ p: 60 })}>
                <Typography
                    sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 1 }),
                        fontWeight: 400,
                        textAlign: 'center',
                        color: `${COLORS.C1}A0`,
                    }}
                >
                    Unlock the power of programming at Genesys Academy and embark on a journey to learn how to build essential skills that propel your career to new heights. Our courses are designed to empower you with hands-on expertise in programming for a dynamic and thriving future.
                </Typography>
            </Grid>
            <Grid item sx={{
                backgroundColor: COLORS.C1 + 'F0',
                borderRadius: size({ p: 1 }),
                marginTop: size({ p: 4, h: true })
            }}>
                <Box sx={{
                    height: size({ p: 35, h: true }),
                    width: size({ p: 32 }),
                }}>
                    <Box display={'flex'} sx={{
                        borderBottom: `${size({ p: 0.5, h: true })} solid #FFFFFF80`,
                        padding: size({ p: 1 }),
                    }}>
                        <ToolBarIcon color={'#FB5E54'} />
                        <ToolBarIcon color={'#FBBA2A'} />
                        <ToolBarIcon color={'#22C03A'} />
                    </Box>
                    <CodeEditor
                        value={text}
                        language={code_keys[cc]}
                        style={{
                            fontSize: size({ p: 1 }),
                            paddingLeft: size({ p: 1 }),
                            backgroundColor: 'transparent',
                            fontFamily: 'Space Mono',
                        }}
                    />
                </Box>
            </Grid>
        </Grid >
    )
}
function Paper2() {
    return (
        <Grid item container flexWrap={'nowrap'} alignItems={'center'}
            justifyContent={'center'} alignContent={'center'}
            columnGap={size({ p: 4 })} height={size({ p: 80, h: true })}
        >
            <Grid item height={size({ p: 84, h: true })}>
                <Lottie width={size({ p: 40 })} height={size({ p: 40 })}
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: design,
                    }} />
            </Grid>
            <Grid item width={size({ p: 40 })}>
                <Typography
                    sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 4.5 }),
                        fontWeight: 700,
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        lineHeight: size({ p: 10.5, h: true }),
                        color: `${COLORS.C2}`,
                    }}
                >
                    Become a professional & certified<br />
                    <Box component='span' sx={{
                        backgroundImage: `url(${DATA.Paper2.design_svg})`,
                        WebkitTextFillColor: 'transparent',
                        WebkitBackgroundClip: 'text',
                        backgroundRepeat: 'repeat',
                        fontSize: size({ p: 7 }),
                        lineHeight: size({ p: 13.5, h: true }),
                        WebkitTextStroke: `${size({ p: 0.1 })} ${COLORS.C1}A0`
                    }}>GRAPHIC<br />DESIGNER</Box>
                </Typography>
            </Grid>
        </Grid >
    )
}
function Paper3() {
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'} alignContent={'center'} rowGap={size({ p: 2, h: true })}
            height={size({ p: 80, h: true })}>
            <Grid item>
                <Typography
                    sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 2.2 }),
                        fontWeight: 600,
                        textAlign: 'center',
                        color: `${COLORS.C2}`,
                    }}
                >
                    Institute of professional foundation courses
                </Typography>
            </Grid>
            <Grid item width={size({ p: 60 })}>
                <Typography
                    sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 1 }),
                        fontWeight: 400,
                        textAlign: 'center',
                        color: `${COLORS.C1}A0`,
                    }}
                >
                    Unlock the power of programming at Genesys Academy and embark on a journey to learn how to build essential skills that propel your career to new heights. Our courses are designed to empower you with hands-on expertise in programming for a dynamic and thriving future.
                </Typography>
            </Grid>
            <Grid item alignItems={'center'} mt={size({ p: 4, h: true })}>
                {DATA.Paper3.icons.map((icons, i) => {
                    return (
                        <Grid item container alignItems={'center'}
                            justifyContent={'center'} sx={{
                                borderBottom: (i === 2) ? 'none' : (
                                    `${size({ p: 0.1 })} solid ${COLORS.C1}50`
                                )
                            }}>
                            {icons.map((icon, j) => {
                                const Icon = icon
                                return (
                                    <Grid item sx={{
                                        padding: size({ p: 2 }),
                                        borderRight: (j === 9) ? 'none' : (
                                            `${size({ p: 0.1 })} solid ${COLORS.C1}50`
                                        )
                                    }}>
                                        <Icon
                                            width={size({ p: 2.5 })}
                                            height={size({ p: 2.5 })}
                                            fill={COLORS.C2} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )
                })}
            </Grid>
        </Grid >
    )
}

function IndicatorIcon(props) {
    const { play, setPlay, currentIndex, setCurrentIndex } = props
    const Icon = {
        true: PauseIcon,
        false: PlayIcon
    }[play]
    const dot_sx = {
        width: size({ p: 1.4, h: true }),
        height: size({ p: 1.4, h: true }),
        border: `${size({ p: 0.1 })} solid white`,
        cursor: 'pointer',
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: 'white'
        }
    }
    return (
        <Grid item container flexWrap={'nowrap'} width={size({ p: 7 })} height={size({ p: 4.5, h: true })}
            alignItems={'center'} justifyContent={'center'} alignContent={'center'} justifyItems={'center'}
            columnGap={size({ p: 0.5 })}
            sx={{
                backgroundColor: COLORS.C1 + 'E0',
                borderRadius: size({ p: 1 })
            }}>
            <Grid item>
                <Box onClick={() => { setCurrentIndex(0) }} sx={{
                    ...dot_sx,
                    backgroundColor: currentIndex === 0 ? 'white' : 'transparent'
                }} />
            </Grid>
            <Grid item>
                <Box onClick={() => { setCurrentIndex(1) }} sx={{
                    ...dot_sx,
                    backgroundColor: currentIndex === 1 ? 'white' : 'transparent'
                }} />
            </Grid>
            <Grid item>
                <Box onClick={() => { setCurrentIndex(2) }} sx={{
                    ...dot_sx,
                    backgroundColor: currentIndex === 2 ? 'white' : 'transparent'
                }} />
            </Grid>
            <Grid item>
                <Box sx={{
                    width: size({ p: 0.05 }),
                    height: size({ p: 3, h: true }),
                    backgroundColor: 'white'
                }} />
            </Grid>
            <Grid item sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Icon
                    onClick={() => { setPlay(!play) }}
                    width={size({ p: 1.2 })}
                    height={size({ p: 1.2 })}
                    fill={'white'}
                    style={{
                        cursor: 'pointer',
                    }} />
            </Grid>
        </Grid>
    )
}

export default function Courses() {
    const [play, setPlay] = useState(true)
    const [currentIndex, setCurrentIndex] = useState(0)
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'} rowGap={size({ p: 4, h: true })}
            sx={{
                width: size({ p: 100 }),
                height: size({ p: 85, h: true }),
            }}>
            <Carousel
                indicators={false}
                index={currentIndex}
                navButtonsAlwaysVisible={false}
                navButtonsProps={{
                    style: {
                        backgroundColor: COLORS.C1
                    }
                }}
                autoPlay={play}
                onChange={(index) => { setCurrentIndex(index) }}
                swipe={false}
                sx={{
                    width: size({ p: 100 }),
                    height: size({ p: 80, h: true }),
                }}>
                <Paper1 />
                <Paper2 />
                <Paper3 />
            </Carousel>
            <IndicatorIcon
                play={play}
                setPlay={setPlay}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex} />
        </Grid>
    )
}