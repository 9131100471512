export function size({ p, w = true, h = false, f = false, px = true, neg = false }) {
    const { innerWidth: width, innerHeight: height } = window;
    let c_size = null
    if (h)
        c_size = (height / 100) * p
    else if (f)
        c_size = ((height / 100) * (width / 100)) * p
    else if (w)
        c_size = (width / 100) * p
    if (px)
        c_size = `${c_size}px`
    if (neg)
        c_size = '-'.concat(c_size)
    return c_size
}


export const COLORS = {
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    C1: '#002E3A',
    C2: '#1B4A50',
    C3: '#64DCC8',
    C4: '#031F2A',
    C5: '#313131',
    C6: '#6C62FF',
}