import React from "react";
import { Grid, Typography, TextField, OutlinedInput, Select, MenuItem, Pagination, Button, CardMedia } from "@mui/material";
import { size, COLORS } from "../../utils";
import SearchIcon from '../../assets/icons/search'
import { useState } from "react";
import { Data } from "./Data";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import Enquire from "./Enquire";
import ProjectsList from '../../assets/extras/ProjectsList.json'


const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            border: `${size({ p: 0.1 })} solid ${COLORS.C1}`,
        },
        "&:hover $notchedOutline": {
            border: `${size({ p: 0.1 })} solid ${COLORS.C1}`,
        },
        "&$focused $notchedOutline": {
            border: `${size({ p: 0.1 })} solid ${COLORS.C1}`,
        }
    },
    focused: {
        border: `${size({ p: 0.1 })} solid ${COLORS.C1}`,
    },
    notchedOutline: {
        border: `${size({ p: 0.1 })} solid ${COLORS.C1}`,
    }
}));

export default function Project() {
    const [domain, setDomain] = useState('All')
    const [search, setSearch] = useState('')
    const [searchChanged, setSearchChanged] = useState(false)
    const [domainChanged, setDomainChanged] = useState(false)
    const [open, setOpen] = useState(false)
    const data = [
        { label: 'All' },
        ...Data,
    ]
    const [projectsList,] = useState(ProjectsList)
    const [projectsList1, setProjectsList1] = useState(ProjectsList)
    useEffect(() => {
        if (domainChanged || searchChanged) {
            if (domain === 'All') {
                setProjectsList1(projectsList)
            }
            else {
                let pl1 = []
                projectsList.map((k) => {
                    if (domain.toLowerCase() === k.Domain.toLowerCase()) {
                        pl1 = [...pl1, k]
                    }
                    return ('')
                })
                setProjectsList1(pl1)
            }
            setDomainChanged(false)
            if (search.length > 4) {
                let pl1 = []
                projectsList1.map((k) => {
                    if (k.Title.toLowerCase().includes(search.toLowerCase())) {
                        pl1 = [...pl1, k]
                    }
                    return ('')
                })
                setProjectsList1(pl1)
            }
            setSearchChanged(false)
        }
    }, [domainChanged, searchChanged, domain, projectsList, projectsList1, search])
    const ois = useOutlinedInputStyles()
    const [page, setPage] = useState(1);
    function changeDomain(e) {
        setDomain(e.target.value)
        setDomainChanged(true)
    }
    function searchUpdate(e) {
        setSearch(e.target.value)
        setSearchChanged(true)
    }
    const PROCESS_DATA = [
        {
            icon: '/assets/images/projects/selection.png',
            name: 'Title Selection',
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
        }, {
            icon: '/assets/images/projects/implementation.png',
            name: 'Implementation',
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
        }, {
            icon: '/assets/images/projects/explain.png',
            name: 'Explaination',
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
        }, {
            icon: '/assets/images/projects/update.png',
            name: 'Updation',
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
        }, {
            icon: '/assets/images/projects/document.png',
            name: 'Documentation',
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
        },
    ]
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'} rowGap={size({ p: 5, h: true })}>
            <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
                rowGap={size({ p: 0.5, h: true })}>
                <Grid item>
                    <Typography sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 2 }),
                        fontWeight: 600,
                        color: COLORS.C2,
                    }}>
                        Genesys Projects
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{
                        fontFamily: 'Space Mono',
                        fontSize: size({ p: 0.9 }),
                        fontWeight: 200,
                        color: COLORS.C2,
                    }}>
                        Welcome to the friendliest place for finding your project!
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container flexWrap={'nowrap'} alignItems={'center'} justifyContent={'center'}>
                <Grid item container flexWrap={'nowrap'} alignItems={'center'} width={size({ p: 49 })}
                    columnGap={size({ p: 1 })}>
                    <Grid item>
                        <Typography sx={{
                            fontFamily: 'Space Mono',
                            fontSize: size({ p: 0.9 }),
                            fontWeight: 200,
                            color: COLORS.C2,
                        }}>
                            Domain :
                        </Typography>
                    </Grid>
                    <Grid item width={size({ p: 18 })}>
                        <Select
                            fullWidth
                            value={domain}
                            defaultValue={domain}
                            onChange={(e) => { changeDomain(e) }}
                            input={<OutlinedInput classes={ois} />}
                            sx={{
                                fontFamily: 'Space Mono',
                                color: COLORS.C2,
                                border: `none`,
                                borderRadius: 0,
                            }}
                        >
                            {
                                data.map((k) => {
                                    return (
                                        <MenuItem value={k.label} sx={{
                                            fontFamily: 'Space Mono',
                                            color: COLORS.C2,
                                            backgroundColor: COLORS.WHITE,
                                        }}>{k.label}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </Grid>
                </Grid>
                <Grid item width={size({ p: 18 })} sx={{
                    border: `${size({ p: 0.08 })} solid ${COLORS.C2}`,
                }}>
                    <TextField
                        placeholder={'Search'}
                        fullWidth
                        value={search}
                        onChange={(e) => { searchUpdate(e) }}
                        InputProps={{
                            style: {
                                fontFamily: 'Space Mono',
                                color: COLORS.C2,
                                fontSize: size({ p: 1 }),
                                height: size({ p: 2.5 }),
                            }
                        }}
                        sx={{
                            "& fieldset": { border: 'none' },
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    border: `${size({ p: 0.08 })} solid ${COLORS.C2}`,
                                    borderRadius: 0,
                                }
                            }
                        }}
                    >
                    </TextField>
                </Grid>
                <Grid item sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: COLORS.C2,
                    padding: size({ p: 0.3 })
                }}>
                    <SearchIcon
                        fill={COLORS.WHITE}
                        width={size({ p: 2 })}
                        height={size({ p: 2 })} />
                </Grid>
            </Grid>
            <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
                width={size({ p: 70 })} rowGap={size({ p: 1, h: true })}>
                {
                    projectsList1 ? (projectsList1.slice((page - 1) * 10, page * 10).map((k) => {
                        return (
                            <Grid item container flexWrap={'nowrap'} width={size({ p: 70 })}
                                alignItems={'center'} columnGap={size({ p: 1 })}
                                padding={`0px ${size({ p: 2 })}`}
                                height={size({ p: 12, h: true })} sx={{
                                    backgroundColor: COLORS.WHITE,
                                    border: `${size({ p: 0.08 })} solid ${COLORS.BLACK}A0`,
                                    borderRight: `${size({ p: 0.25 })} solid ${COLORS.BLACK}A0`,
                                }}>
                                <Grid item width={size({ p: 6 })} minWidth={size({ p: 6 })}>
                                    <Typography sx={{
                                        fontFamily: 'Space Mono',
                                        fontSize: size({ p: 1 }),
                                        fontWeight: 600,
                                        color: COLORS.C2,
                                    }}>
                                        {k.Code}
                                    </Typography>
                                </Grid>
                                <Grid item width={size({ p: 0.08 })} height={size({ p: 10, h: true })}
                                    sx={{
                                        backgroundColor: COLORS.BLACK + 'A0',
                                    }}>
                                </Grid>
                                <Grid item width={size({ p: 46 })} minWidth={size({ p: 46 })}>
                                    <Typography sx={{
                                        fontFamily: 'Space Mono',
                                        fontSize: size({ p: 1 }),
                                        fontWeight: 400,
                                        color: COLORS.C2,
                                    }}>
                                        {k.Title}
                                    </Typography>
                                </Grid>
                                <Grid item width={size({ p: 0.08 })} height={size({ p: 10, h: true })}
                                    sx={{
                                        backgroundColor: COLORS.BLACK + 'A0',
                                    }}>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{
                                        fontFamily: 'Space Mono',
                                        fontSize: size({ p: 1 }),
                                        fontWeight: 600,
                                        color: COLORS.C2,
                                    }}>
                                        {k.Domain}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    })) : (<></>)
                }
            </Grid>
            {
                projectsList1 ? (
                    <Grid item>
                        <Pagination
                            count={parseInt(projectsList1.length / 10)}
                            defaultPage={page}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={(_, page) => {
                                setPage(page)
                            }}
                            sx={{
                                '.MuiPaginationItem-root': {
                                    backgroundColor: COLORS.C3,
                                    fontFamily: 'Space Mono',
                                    color: COLORS.C8,
                                    margin: `0px ${size({ p: 0.5 })}`,
                                    '&.Mui-selected': {
                                        backgroundColor: COLORS.C2,
                                        color: COLORS.WHITE,
                                    },
                                    '&:hover': {
                                        backgroundColor: COLORS.C2 + 'A0',
                                        color: COLORS.WHITE,
                                    }
                                },
                            }} />
                    </Grid>
                ) : (<></>)
            }
            <Grid item container flexWrap={'nowrap'} direction={'column'} width={size({ p: 80 })}
                alignItems={'center'} justifyContent={'center'} rowGap={size({ p: 0.5, h: true })}
                height={size({ p: 60, h: true })} sx={{
                    background: `radial-gradient(57.03% 76.98% at 100% 0%,#ffffff 0%,rgba(255,255,255,0) 100%),#031c26`,
                    backgroundBlendMode: 'overlay,normal',
                    borderRadius: size({ p: 1.5 }),
                    marginTop: size({ p: 10, h: true })
                }}
            >
                <Grid item>
                    <Typography sx={{
                        fontFamily: 'Archia',
                        fontSize: size({ p: 2 }),
                        fontWeight: 600,
                        color: COLORS.WHITE,
                    }}>
                        What's included in project_
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 1 }),
                            fontWeight: 400,
                            textAlign: 'center',
                            color: `${COLORS.WHITE}`,
                            padding: `0px ${size({ p: 15 })}`
                        }}
                    >
                        From precise title selection and detailed explanations to regular updates and meticulous documentation, we cover every facet to ensure your project's success.
                    </Typography>
                </Grid>
                <Grid item container flexWrap={'nowrap'} alignItems={'center'} justifyContent={'center'}
                    marginTop={size({ p: 6, h: true })} marginBottom={size({ p: 6, h: true })}>
                    {
                        PROCESS_DATA.map((k) => {
                            return (
                                <Grid item container flexWrap={'nowrap'} direction={'column'}
                                    alignItems={'center'} justifyContent={'center'}
                                    width={size({ p: 15 })}>
                                    <Grid item>
                                        <CardMedia
                                            component={'img'}
                                            image={k.icon} sx={{
                                                width: size({ p: 7 }),
                                                height: size({ p: 7 }),
                                            }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{
                                            fontFamily: 'Space Mono',
                                            fontSize: size({ p: 1.2 }),
                                            fontWeight: 600,
                                            color: COLORS.WHITE,
                                            marginTop: size({ p: 2, h: true })
                                        }}>
                                            {k.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Grid item width={size({ p: 10 })}>
                    <Button variant="contained" fullWidth
                        onClick={() => { setOpen(true) }}
                        sx={{
                            backgroundColor: `${COLORS.C3}`,
                            fontFamily: 'Space Mono',
                            fontWeight: 400,
                            fontSize: size({ p: 1 }),
                            color: COLORS.BLACK,
                            textTransform: 'none',
                            ':hover': {
                                backgroundColor: `${COLORS.C3}`,
                            },
                        }}>Enquire Now</Button>
                </Grid>
            </Grid>
            <Enquire onClose={() => setOpen(false)} data={projectsList} open={open} />
        </Grid>
    )
}