export const Data = [
    { label: 'Machine Learning', icon: '/assets/images/projects/machine_learning.png' },
    { label: 'Deep Learning', icon: '/assets/images/projects/deep_learning.png' },
    { label: 'Computer Vision', icon: '/assets/images/projects/computer_vision.png' },
    { label: 'Natural Language Processing', icon: '/assets/images/projects/nlp.png' },
    { label: 'Information Retrieval', icon: '/assets/images/projects/information_retrieval.png' },
    { label: 'Blockchain', icon: '/assets/images/projects/blockchain.png' },
    { label: 'Bioinformatics', icon: '/assets/images/projects/bioinformatics.png' },
    { label: 'Big Data', icon: '/assets/images/projects/bigdata.png' },
    { label: 'Data Mining', icon: '/assets/images/projects/data_mining.png' },
    { label: 'Network Security', icon: '/assets/images/projects/network_security.png' },
    { label: 'Cryptography', icon: '/assets/images/projects/cryptography.png' },
    { label: 'Internet Of Things', icon: '/assets/images/projects/iot.png' },
    { label: 'Wireless Sensor Networks', icon: '/assets/images/projects/wsn.png' },
    { label: 'Web Developement', icon: '/assets/images/projects/web.png' },
    { label: 'Mobile App Developement', icon: '/assets/images/projects/mobile.png' },
    { label: 'API Development', icon: '/assets/images/projects/api.png' },
]