import React from "react"
import { Grid, Typography, Button } from "@mui/material"
import { size, COLORS } from "../../utils";
import { DATA } from '../Careers/Data'
import Round from '../../assets/extras/round'


function CareerGrid(props) {
    const { data } = props
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} width={size({ p: 22 })}
            justifyContent={'flex-end'} rowGap={size({ p: 1, h: true })}
            height={size({ p: 50, h: true })} sx={{
                borderRadius: size({ p: 1.2 }),
                position: 'relative',
                background: `linear-gradient(180deg,rgba(0,0,0,0) 27.89%,rgba(0,0,0,.88) 74.56%), url(${data.BG}) center`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                paddingLeft: size({ p: 2.2 }),
                paddingRight: size({ p: 1 }),
                paddingBottom: size({ p: 4, h: true }),
            }}>
            <Grid item>
                <Typography sx={{
                    fontFamily: 'Archia',
                    fontSize: size({ p: 1.1 }),
                    fontWeight: 600,
                    color: COLORS.WHITE,
                }}>
                    {data.Title}
                </Typography>
            </Grid>
            <Grid item>
                <Typography sx={{
                    fontFamily: 'Archia',
                    fontSize: size({ p: 0.9 }),
                    fontWeight: 400,
                    color: COLORS.WHITE + 'D0',
                    textAlign: 'left'
                }}>
                    {data.BasicDescription}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default function Careers() {
    return (
        <Grid item container flexWrap={'nowrap'} width={size({ p: 80 })} alignItems={'center'}
            justifyContent={'center'} columnGap={size({ p: 2 })}
            m={`${size({ p: 2, h: true })} 0px`}>
            <CareerGrid data={DATA[0]} />
            <Grid item container width={size({ p: 22 })} flexWrap={'nowrap'} direction={'column'}
                rowGap={size({ p: 4, h: true })}>
                <CareerGrid data={DATA[1]} />
                <Grid item container flexWrap={'nowrap'} direction={'column'}
                    rowGap={size({ p: 3.5, h: true })} height={size({ p: 26, h: true })} sx={{
                        background: `radial-gradient(57.03% 76.98% at 100% 0%,#ffffff 0%,rgba(255,255,255,0) 100%),#031c26`,
                        backgroundBlendMode: 'overlay,normal',
                        borderRadius: size({ p: 1.2 }),
                        padding: `${size({ p: 5, h: true })} ${size({ p: 2 })}`,
                        position: 'relative'
                    }}>
                    <Grid item>
                        <Typography
                            sx={{
                                fontFamily: 'Archia',
                                fontSize: size({ p: 1.3 }),
                                fontWeight: 600,
                                color: COLORS.WHITE,
                            }}
                        >
                            Careers at genesys, join our team
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                            onClick={() => {

                            }}
                            sx={{
                                backgroundColor: `${COLORS.WHITE}`,
                                fontFamily: 'Archia',
                                fontWeight: 600,
                                color: COLORS.C1,
                                fontSize: size({ p: 0.9 }),
                                textTransform: 'none',
                                borderRadius: size({ p: 3 }),
                                padding: `${size({ p: 0.8, h: true })} ${size({ p: 1.2 })}`,
                                ':hover': {
                                    backgroundColor: `${COLORS.WHITE}`,
                                },
                            }}>Apply today</Button>
                    </Grid>
                    <Grid item sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        width: 204,
                        height: 204,
                    }}>
                        <Round />
                    </Grid>
                </Grid>
                <CareerGrid data={DATA[2]} />
            </Grid>
            <CareerGrid data={DATA[3]} />
        </Grid>
    )
}
