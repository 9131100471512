import * as React from "react";

function XDIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M18.835.3H5.165A5.165 5.165 0 000 5.465v13.07A5.165 5.165 0 005.165 23.7h13.67A5.165 5.165 0 0024 18.535V5.465A5.165 5.165 0 0018.835.3zm-6.106 16.491h-2.287c-.16 0-.274-.006-.338-.113-.215-.419-.43-.835-.644-1.248a45.137 45.137 0 00-.684-1.264 66.961 66.961 0 01-.717-1.305h-.016a52.7 52.7 0 01-.668 1.288c-.23.429-.459.856-.684 1.28A54.85 54.85 0 016 16.693c-.044.097-.119.107-.227.107H3.568c-.043 0-.067.017-.072-.026a.166.166 0 01.024-.113l3.107-5.105L3.6 6.438c-.033-.043-.038-.078-.016-.107a.115.115 0 01.097-.04h2.27a.36.36 0 01.145.024.292.292 0 01.096.089c.193.43.408.859.644 1.288.236.429.475.853.716 1.272.241.419.464.843.668 1.272h.016c.213-.44.43-.869.652-1.288.222-.419.447-.84.676-1.264.231-.425.453-.847.668-1.264a.246.246 0 01.064-.106.239.239 0 01.129-.024h2.109a.093.093 0 01.112.067.093.093 0 01-.031.094l.001.004-2.995 4.943 3.204 5.249c.021.037.026.08.016.121-.01.036-.047.012-.112.023zm7.681-.42a8.02 8.02 0 01-1.626.483 8.63 8.63 0 01-1.547.145 5.006 5.006 0 01-2.149-.45 3.421 3.421 0 01-1.506-1.361 4.348 4.348 0 01-.548-2.278 4.202 4.202 0 01.548-2.109 4.018 4.018 0 011.595-1.545 5.139 5.139 0 012.737-.572c.086.005.188.013.306.024V5.536c0-.075.032-.113.097-.113h2.028a.086.086 0 01.097.073.096.096 0 010 .024v9.517c0 .183.008.381.024.596.016.214.03.407.04.579a.163.163 0 01-.096.159zm-2.457-5.812c.09.009.179.025.266.048v4.395a1.634 1.634 0 01-.354.064 4.965 4.965 0 01-.419.016 2.656 2.656 0 01-.83-.129 1.714 1.714 0 01-.676-.402 1.973 1.973 0 01-.451-.708 2.83 2.83 0 01-.169-1.031 2.217 2.217 0 01.33-1.272c.213-.316.504-.571.845-.741.35-.173.736-.262 1.127-.258.111.002.221.007.331.018z" />
    </svg>
  );
}

export default XDIcon;
