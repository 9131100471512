import React, { useState } from "react"
import { Dialog, Slide, Grid, CardMedia, Typography, TextField as TF, Button, Autocomplete } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { size, COLORS } from "../../utils"
import space from '../../assets/lottiefiles/space.json'
import Lottie from 'react-lottie';
import { EDUCATION, WORK_EXPERIENCE } from './Data'

const useStyles = makeStyles(theme => ({
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderWidth: size({ p: 0 }),
        }
    },
    cssFocused: {
        borderWidth: size({ p: 0 }),
    },
    notchedOutline: {
        borderWidth: size({ p: 0 }),
    },
}))

function TextField({ label, tf_props, params }) {
    const cls = useStyles()
    params = (params === undefined) ? {} : params
    return (
        <Grid item container direction={'column'}>
            <Grid item>
                <Typography
                    sx={{
                        fontFamily: 'Space Mono',
                        fontSize: size({ p: 0.9 }),
                        fontWeight: 400,
                        wordSpacing: size({ p: 0.2, neg: true }),
                        marginBottom: size({ p: 0.5 }),
                        color: `${COLORS.WHITE}A0`,
                    }}
                >{label}</Typography>
            </Grid>
            <Grid item>
                <TF
                    placeholder={label}
                    {...tf_props}
                    {...params}
                    InputProps={{
                        style: {
                            fontFamily: 'Space Mono',
                            color: COLORS.WHITE,
                            backgroundColor: '#242424',
                            fontSize: size({ p: 0.9 })
                        },
                        classes: {
                            root: cls.cssOutlinedInput,
                            focused: cls.cssFocused,
                            notchedOutline: cls.notchedOutline,
                        },
                        ...params.InputProps,
                    }}
                />
            </Grid>
        </Grid>
    )
}


export default function Apply(props) {
    const { onClose, open, data } = props
    const [resumeFile, setResumeFile] = useState([])
    return (
        <Dialog open={open} TransitionComponent={Slide} fullScreen>
            <div style={{
                width: size({ p: 100 }),
                height: size({ p: 100, h: true }),
                backgroundColor: COLORS.WHITE,
                backgroundImage: `
                    linear-gradient(${COLORS.C5}40 ${size({ p: 0.06 })}, transparent ${size({ p: 0.06 })}), 
                    linear-gradient(to right, ${COLORS.C5}40 ${size({ p: 0.06 })}, ${COLORS.WHITE} ${size({ p: 0.06 })})
                    `,
                backgroundSize: `${size({ p: 2.5 })} ${size({ p: 2 })}`,
                position: 'relative',
                display: 'grid',
                alignItems: 'center',
            }}>
                <div style={{
                    position: "absolute",
                    left: size({ p: 60 }),
                }}>
                    <Lottie width={size({ p: 40 })} height={size({ p: 40 })}
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: space,
                        }} />
                </div>
                <div style={{
                    marginLeft: size({ p: 5 }),
                    position: 'relative',
                }}>
                    <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
                        justifyContent={'start'} width={size({ p: 50 })} height={size({ p: 90, h: true })}
                        rowGap={size({ p: 1.5 })} sx={{
                            backgroundColor: COLORS.C5,
                            borderRadius: size({ p: 0.6 }),
                            position: 'relative',
                            padding: `0px ${size({ p: 2 })}`,
                        }}>
                        <Grid item>
                            <Typography onClick={() => onClose()} sx={{
                                fontFamily: 'Space Mono',
                                fontSize: size({ p: 3 }),
                                color: COLORS.WHITE,
                                position: 'absolute',
                                cursor: 'pointer',
                                right: size({ p: 2 }),
                            }}>
                                x
                            </Typography>
                        </Grid>
                        <Grid item container flexWrap={'nowrap'} alignItems={'center'}
                            justifyContent={'start'} columnGap={size({ p: 1 })}>
                            <Grid item>
                                <CardMedia
                                    component={'img'}
                                    image={'/assets/images/careers/apply.png'} sx={{
                                        width: size({ p: 5 }),
                                        height: size({ p: 5 })
                                    }} />
                            </Grid>
                            <Grid item>
                                <Typography sx={{
                                    fontFamily: 'Space Mono',
                                    fontSize: size({ p: 1 }),
                                    fontWeight: 400,
                                    color: `${COLORS.WHITE}A0`,
                                    textAlign: 'left'
                                }}>Appy To Position</Typography>
                                <Typography sx={{
                                    fontFamily: 'Space Mono',
                                    fontSize: size({ p: 1.4 }),
                                    fontWeight: 600,
                                    color: COLORS.WHITE,
                                    textAlign: 'left'
                                }}>{data.Title}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container flexWrap={'nowrap'} columnGap={size({ p: 1 })}>
                            <Grid item container flexWrap={'nowrap'} direction={'column'}
                                rowGap={size({ p: 1, h: true })}>
                                <Grid item>
                                    <TextField label="First Name" tf_props={{
                                        fullWidth: 'true'
                                    }} />
                                </Grid>
                                <Grid item>
                                    <TextField label="Email Address" tf_props={{
                                        fullWidth: 'true'
                                    }} />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        options={EDUCATION}
                                        height={size({ p: 8, h: true })}
                                        renderInput={
                                            (params) => {
                                                console.log(params)
                                                return (
                                                    <TextField
                                                        params={params}
                                                        label={'Education'}
                                                    />
                                                )
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField label="Describe Your Current Work" tf_props={{
                                        fullWidth: 'true',
                                        multiline: 'true',
                                        rows: 5,
                                    }} />
                                </Grid>
                            </Grid>
                            <Grid item container flexWrap={'nowrap'} direction={'column'}
                                rowGap={size({ p: 1, h: true })}>
                                <Grid item>
                                    <TextField label="Last Name" tf_props={{
                                        fullWidth: 'true'
                                    }} />
                                </Grid>
                                <Grid item>
                                    <TextField label="Contact Number" tf_props={{
                                        fullWidth: 'true'
                                    }} />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        options={WORK_EXPERIENCE}
                                        height={size({ p: 8, h: true })}
                                        renderInput={
                                            (params) => {
                                                return (
                                                    <TextField
                                                        params={params}
                                                        label={'Work Experience'}
                                                    />
                                                )
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item container flexWrap={'nowrap'} direction={'column'}
                                    alignItems={'center'} justifyContent={'center'}
                                    height={size({ p: 20, h: true })}
                                    margin={`${size({ p: 2, h: true })} 0px`}
                                    rowGap={size({ p: 2, h: true })} sx={{
                                        border: `${size({ p: 0.4, h: true })} dashed ${COLORS.WHITE}C0`,
                                        borderRadius: size({ p: 1 })
                                    }}>
                                    <Grid item>
                                        <Typography sx={{
                                            fontFamily: 'Space Mono',
                                            fontSize: size({ p: 1 }),
                                            fontWeight: 400,
                                            color: `${COLORS.WHITE}A0`,
                                        }}>
                                            Attach Your Resume
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            component="label"
                                            sx={{
                                                backgroundColor: `${COLORS.C6}`,
                                                fontFamily: 'Space Mono',
                                                fontWeight: 400,
                                                fontSize: size({ p: 0.8 }),
                                                color: COLORS.WHITE,
                                                textTransform: 'none',
                                                ':hover': {
                                                    backgroundColor: `${COLORS.BG}`,
                                                }
                                            }}>
                                            Upload
                                            <input type="file" hidden accept=".pdf"
                                                onChange={(e) => {
                                                    if (e.target.files.length > 0)
                                                        setResumeFile(e.target.files)
                                                    else
                                                        setResumeFile([])
                                                }} />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography value={resumeFile} sx={{
                                            fontFamily: 'Space Mono',
                                            fontSize: size({ p: 0.9 }),
                                            fontWeight: 400,
                                            color: `${COLORS.WHITE}`,
                                            border: `${size({ p: 0.1 })} solid ${COLORS.WHITE}C0`,
                                            padding: `${size({ p: 0.5, h: true })} ${size({ p: 0.5 })}`,
                                            borderRadius: size({ p: 0.4 }),
                                        }}>
                                            {
                                                resumeFile.length > 0 ? (
                                                    resumeFile[0].name.length > 20 ? (
                                                        resumeFile[0].name.slice(0, 10)
                                                        +
                                                        '...'
                                                        +
                                                        resumeFile[0].name.slice(-10, -1)
                                                    ) : resumeFile[0].name
                                                ) : 'No File Choosen'
                                            }
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container flexWrap={'nowrap'} direction={'column'}
                            padding={`0px ${size({ p: 5 })}`} rowGap={size({ p: 0.5, h: true })}>
                            <Grid item>
                                <Button variant="contained" fullWidth sx={{
                                    backgroundColor: `${COLORS.C6}`,
                                    fontFamily: 'Space Mono',
                                    fontWeight: 400,
                                    fontSize: size({ p: 1 }),
                                    color: `${COLORS.WHITE}`,
                                    textTransform: 'none',
                                    ':hover': {
                                        backgroundColor: `${COLORS.C6}`,
                                    },
                                }}>Submit</Button>
                            </Grid>
                            <Grid item>
                                <Typography sx={{
                                    fontFamily: 'Space Mono',
                                    fontSize: size({ p: 0.7 }),
                                    fontWeight: 400,
                                    textAlign: 'center',
                                    color: `${COLORS.WHITE}A0`,
                                }}>Thank you for filling out the form, someone from our team will contact you soon.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Dialog>
    )
}