import { DATA as FiveDaysML } from './Syllabus/FiveDays/ml'

export const DATA = {
    ASSOCIATED: [
        ['PEC', '/assets/images/workshops/panimalar.png'],
        ['APEC', '/assets/images/workshops/apec.png'],
        ['SMVEC', '/assets/images/workshops/smvec.png'],
        ['MIT', '/assets/images/workshops/mit.png'],
        ['MEC', '/assets/images/workshops/mailam.png'],
        ['GPC', '/assets/images/workshops/gpc.png'],
    ],
    'Five Days': {
        1: {
            name: 'Machine Learning',
            icon: '/assets/images/workshops/ml.png',
            sname: 'ml',
            syllabus: FiveDaysML,
            type: 1,
        },
        2: {
            name: 'Computer Vision',
            icon: '/assets/images/workshops/cv.png',
            sname: 'cv',
            syllabus: FiveDaysML,
            type: 1,
        },
        3: {
            name: 'Data Science',
            icon: '/assets/images/workshops/ds.png',
            sname: 'ds',
            syllabus: FiveDaysML,
            type: 1,
        },
    },
    'Three Days': {
        1: {
            name: 'Machine Learning',
            icon: '/assets/images/workshops/ml.png',
            sname: 'ml',
            syllabus: FiveDaysML,
            type: 1,
        },
        2: {
            name: 'Computer Vision',
            icon: '/assets/images/workshops/cv.png',
            sname: 'cv',
            syllabus: FiveDaysML,
            type: 1,
        },
        3: {
            name: 'Data Science',
            icon: '/assets/images/workshops/ds.png',
            sname: 'ds',
            syllabus: FiveDaysML,
            type: 1,
        },
    },
    'Two Days': {
        1: {
            name: 'Machine Learning',
            icon: '/assets/images/workshops/ml.png',
            sname: 'ml',
            syllabus: FiveDaysML,
            type: 1,
        },
    },
    'One Day': {
        1: {
            name: 'Machine Learning',
            icon: '/assets/images/workshops/ml.png',
            sname: 'ml',
            syllabus: FiveDaysML,
            type: 1,
        },
    },
}