import React, { useRef } from 'react';
import { Grid, Box, Typography, useTheme, CardMedia } from "@mui/material";
import SwipeableViews from 'react-swipeable-views';
import { size, COLORS } from '../../utils';
import ArrowLeftSquareFillIcon from '../../assets/icons/arrowleftsquarefill'
import ArrowRightSquareFillIcon from '../../assets/icons/arrowrightsquarefill'
import Enroll from './Enroll';
import { useState } from 'react';
import { Link } from 'react-router-dom';

let ALLCOURSESVIEW = {

}


function CourseView(props) {
    const { data } = props
    const elementRef = useRef(null);
    const [open, setOpen] = useState(false)
    const [enrollData, setEnrollData] = useState({})
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'} justifyContent={'center'}
            mt={size({ p: 4, h: true })} rowGap={size({ p: 1 })} >
            <Box display={'flex'} columnGap={size({ p: 1 })} width={size({ p: 72 })}
                ml={size({ p: 1 })}
                ref={elementRef} sx={{
                    scrollBehavior: 'smooth',
                    overflow: 'hidden',
                }}>
                {
                    Object.keys(data).map((k) => {
                        return (
                            <Grid item container flexWrap={'nowrap'} direction={'column'}
                                height={size({ p: 40, h: true })}
                                alignItems={'center'} justifyContent={'space-between'} rowGap={size({ p: 1, h: true })}
                                width={size({ p: 17 })}
                                sx={{
                                    border: `${size({ p: 0.1 })} solid ${COLORS.C2}`,
                                    borderRadius: size({ p: 0.8 }),
                                    backgroundColor: COLORS.WHITE + '80',
                                }}>
                                <Grid item width={size({ p: 17 })} sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Typography sx={{
                                        fontFamily: 'Space Mono',
                                        fontSize: size({ p: 1 }),
                                        fontWeight: 600,
                                        color: COLORS.C1,
                                        textAlign: 'center',
                                        width: size({ p: 16.9 }),
                                        padding: `${size({ p: 1, h: true })} ${size({ p: 1 })}`,
                                        backgroundColor: COLORS.C3,
                                        borderTopLeftRadius: size({ p: 0.8 }),
                                        borderTopRightRadius: size({ p: 0.8 }),
                                        border: 'none',
                                        borderBottom: `${size({ p: 0.1 })} solid ${COLORS.BLACK}`,

                                    }}>
                                        {data[k].name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <CardMedia
                                        component={'img'}
                                        image={data[k].icon}
                                        sx={{
                                            width: size({ p: 12 }),
                                            height: size({ p: 12 })
                                        }} />
                                </Grid>
                                <Grid item container alignItems={'center'} justifyContent={'center'}
                                    height={size({ p: 5, h: true })}
                                    sx={{
                                        borderTop: `${size({ p: 0.15 })} dashed ${COLORS.C2}`,
                                    }}>
                                    <Grid item width={size({ p: 8 })}>
                                        <Link to={`/courses/${data[k].name.split(' ').join('').toLowerCase()
                                            }`} style={{
                                                textDecoration: "none",
                                            }}>
                                            <Typography sx={{
                                                fontFamily: 'Space Mono',
                                                fontSize: size({ p: 0.9 }),
                                                fontWeight: 400,
                                                color: COLORS.C1 + 'E0',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                borderRight: `${size({ p: 0.15 })} solid ${COLORS.C2}`,
                                                ':hover': {
                                                    color: `${COLORS.C2}`
                                                },
                                            }}>
                                                Explore
                                            </Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item width={size({ p: 8 })}>
                                        <Typography
                                            onClick={() => {
                                                setEnrollData(data[k])
                                                setOpen(true)
                                            }}
                                            sx={{
                                                fontFamily: 'Space Mono',
                                                fontSize: size({ p: 0.9 }),
                                                fontWeight: 400,
                                                color: COLORS.C1 + 'E0',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                ':hover': {
                                                    color: `${COLORS.C2}`
                                                }
                                            }}>
                                            Enroll
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Box>
            <Grid item container flexWrap={'nowrap'} alignItems={'flex-end'} justifyContent={'flex-end'}
                columnGap={size({ p: 1 })} mr={size({ p: 2 })}>
                <Grid item>
                    <ArrowLeftSquareFillIcon
                        width={size({ p: 2 })}
                        height={size({ p: 2 })}
                        fill={`${COLORS.C2}`}
                        onClick={() => {
                            elementRef.current.scrollLeft -= size({ p: 72, px: false })
                        }}
                        style={{
                            cursor: 'pointer'
                        }}
                    />
                </Grid>
                <Grid item>
                    <ArrowRightSquareFillIcon
                        width={size({ p: 2 })}
                        height={size({ p: 2 })}
                        fill={`${COLORS.C2}`}
                        onClick={() => {
                            elementRef.current.scrollLeft += size({ p: 72, px: false })
                        }}
                        style={{
                            cursor: 'pointer'
                        }}
                    />
                </Grid>
            </Grid>
            <Enroll onClose={() => setOpen(false)} data={enrollData} open={open} />
        </Grid >
    )
}

function CreateAllCoursesView(data) {
    Object.keys(data).map((k, i) => {
        return (
            ALLCOURSESVIEW[i] = <CourseView data={data[k]} />
        )
    })
}


function CustomTabPanel(props) {
    const { value, index } = props;
    return (
        value === index && (
            ALLCOURSESVIEW[index]
        )
    )
}

const TabPanel = (props) => {
    const { currentTabIndex, setCurrentTabIndex, data } = props
    const theme = useTheme();
    const handleChangeIndex = (index) => {
        setCurrentTabIndex(index);
    };
    CreateAllCoursesView(data)
    return (
        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={currentTabIndex}
            onChangeIndex={handleChangeIndex}
        >
            {
                Object.keys(data).map((_, i) => {
                    return (
                        <CustomTabPanel value={currentTabIndex} index={i} dir={theme.direction} />
                    )
                })
            }
        </SwipeableViews>
    )
}

export default TabPanel;