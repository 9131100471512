import React from "react"
import { Grid, Typography, CardMedia, Button } from "@mui/material"
import { size, COLORS } from "../../utils";
import { Data } from '../Projects/Data'

export default function Projects() {
    let DATA = []
    let i = 0
    while (i < Data.length) {
        DATA = [...DATA, Data.slice(i, i + 4)]
        i = i + 4
    }
    return (
        <Grid item container flexWrap={'nowrap'} width={size({ p: 80 })}
            m={`${size({ p: 2, h: true })} 0px`} height={size({ p: 70, h: true })}
            alignItems={'center'} justifyContent={'center'} sx={{
                background: `radial-gradient(57.03% 76.98% at 100% 0%,#ffffff 0%,rgba(255,255,255,0) 100%),#031c26`,
                backgroundBlendMode: 'overlay,normal',
                borderRadius: size({ p: 1.5 }),
                paddingLeft: size({ p: 4 }),
            }}>
            <Grid item container flexWrap={'nowrap'} direction={'column'} width={size({ p: 35 })}
                rowGap={size({ p: 2, h: true })}>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 2 }),
                            fontWeight: 600,
                            color: COLORS.WHITE,
                        }}
                    >
                        Exploring the best projects in various domains
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 1 }),
                            fontWeight: 300,
                            color: COLORS.WHITE + 'A0',
                        }}
                    >
                        Entrust your project journey to us, from inception to triumph. Receive the fully developed project and its comprehensive explanation within a brief timeframe. Experience a transformative change as we bring efficiency and excellence to every stage of your project.
                    </Typography>
                </Grid>
                <Grid item mt={size({ p: 2, h: true })} sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: size({ p: 1 })
                }}>
                    <Button variant="contained"
                        onClick={() => {

                        }}
                        sx={{
                            backgroundColor: `${COLORS.WHITE}`,
                            fontFamily: 'Archia',
                            fontWeight: 500,
                            color: COLORS.C1,
                            fontSize: size({ p: 0.9 }),
                            textTransform: 'none',
                            borderRadius: size({ p: 2 }),
                            ':hover': {
                                backgroundColor: `${COLORS.WHITE}`,
                            },
                        }}>{'Explore All'}</Button>
                </Grid>
            </Grid>
            <Grid item container flexWrap={'nowrap'} direction={'column'} width={size({ p: 55 })}
                rowGap={size({ p: 3, h: true })} sx={{
                    padding: `0px ${size({ p: 5 })}`
                }}>
                {DATA.map((k, i) => {
                    return (
                        <Grid item container flexWrap={'nowrap'}>
                            {k.map((l, j) => {
                                return (
                                    <Grid item container flexWrap={'nowrap'} direction={'column'}
                                        alignItems={'center'} rowGap={size({ p: 1, h: true })} sx={{
                                            cursor: 'pointer'
                                        }}>
                                        <Grid item sx={{
                                            backgroundColor: COLORS.WHITE,
                                            borderRadius: '50%',
                                            width: size({ p: 3.5 }),
                                            height: size({ p: 3.5 }),
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <CardMedia
                                                component={'img'}
                                                image={l.icon}
                                                sx={{
                                                    width: size({ p: 2 }),
                                                    height: size({ p: 2 })
                                                }} />
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{
                                                fontFamily: 'Archia',
                                                fontSize: size({ p: 0.8 }),
                                                fontWeight: 200,
                                                color: `${COLORS.WHITE}`,
                                                textAlign: 'center',
                                            }}>{l.label}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}
