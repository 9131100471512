import React, { useState } from "react"
import { Grid, Typography, Box, CardMedia, List, ListItem, ListItemIcon, ListItemText, Button } from "@mui/material"
import { size, COLORS } from "../../utils"
import BarChartIcon from '../../assets/icons/bar'
import TimeIcon from '../../assets/icons/time'
import CertificateIcon from '../../assets/icons/certificate'
import ListIcon from '../../assets/icons/tasklist'
import PatchIcon from '../../assets/icons/patch'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Enroll from "./Enroll"
import QuotesLeftIcon from '../../assets/icons/quotesleft'

export default function CourseExplore(props) {
    const { data } = props
    const syllabus = data.syllabus
    const COURSE_TYPES = {
        1: 'Programming',
        2: 'Artificial Intelligence',
        3: 'Web Development',
        4: 'Mobile App Development',
        5: 'Designing',
        6: 'Other',
    }
    const d1 = {
        'Skill level': { icon: BarChartIcon },
        'Time to complete': { icon: TimeIcon },
        'Certificate': { icon: CertificateIcon },
        'Prerequesties': { icon: ListIcon },
    }
    const [expanded, setExpanded] = useState(false)
    const [allExpanded, setAllExpanded] = useState(false)
    const [open, setOpen] = useState(false)
    const CLRS = {
        1: '#A2E8D7E0',
        2: '#9CC0F9E0',
        3: '#FFC8BBE0',
    }
    const handleChange = (index) => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };
    return (
        <div style={{
            backgroundColor: 'transparent',
            position: 'relative'
        }}>
            <div style={{
                top: 0,
                left: 0,
                position: 'absolute',
                width: size({ p: 100 }),
                height: size({ p: 50, h: true }),
                backgroundColor: COLORS.WHITE,
                backgroundImage: `
                radial-gradient(${COLORS.C1} ${size({ p: 0.025 })}, ${COLORS.WHITE} ${size({ p: 0.025 })})`,
                backgroundSize: `${size({ p: 0.2 })} ${size({ p: 0.2 })}`,
            }} />
            <Grid container flexWrap={'nowrap'} direction={'column'}
                rowGap={size({ p: 5, h: true })} position={'relative'}
                alignItems={'center'} justifyContent={'center'}>
                <Grid item container flexWrap={'nowrap'} alignItems={'center'}
                    height={size({ p: 32, h: true })} width={size({ p: 58 })}
                    marginTop={size({ p: 5, h: true })} columnGap={size({ p: 1 })}
                    sx={{
                        background: `radial-gradient(57.03% 76.98% at 100% 0%,#ffffff 0%,rgba(255,255,255,0) 100%),#031c26`,
                        backgroundBlendMode: 'overlay,normal',
                        borderRadius: size({ p: 1.5 }),
                        padding: `0px ${size({ p: 2 })}`,
                        boxShadow: `rgba(0, 0, 0, 0.1) 0px 2px 8px`,
                    }}>
                    <Grid item container flexWrap={'nowrap'} direction={'column'} width={size({ p: 38 })}
                        rowGap={size({ p: 2, h: true })}>
                        <Grid item>
                            <Typography sx={{
                                fontFamily: 'Archia',
                                fontSize: size({ p: 0.9 }),
                                color: COLORS.WHITE,
                            }}>
                                <Box component={'span'} sx={{
                                    color: COLORS.C1,
                                    backgroundColor: COLORS.C3,
                                    padding: `${size({ p: 0.5, h: true })} ${size({ p: 0.8 })}`,
                                    borderRadius: size({ p: 1 }),
                                    marginRight: size({ p: 0.6 }),
                                }}>{COURSE_TYPES[data.type]}</Box>
                                Course
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{
                                fontFamily: 'Archia',
                                fontWeight: 600,
                                fontSize: size({ p: 2.5 }),
                                textTransform: 'capitalize',
                                color: COLORS.WHITE,
                            }}>
                                {data.name.toLowerCase()}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{
                                fontFamily: 'Archia',
                                fontWeight: 400,
                                fontSize: size({ p: 0.9 }),
                                color: COLORS.WHITE + 'A0',
                            }}>
                                {syllabus.description}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CardMedia
                            component={'img'}
                            image={data.icon}
                            sx={{
                                width: size({ p: 12 }),
                                height: size({ p: 12 })
                            }} />
                    </Grid>
                </Grid>
                <Grid item container flexWrap={'nowrap'} alignItems={'center'}
                    justifyContent={'space-evenly'}
                    height={size({ p: 14, h: true })} width={size({ p: 58 })}
                    sx={{
                        backgroundColor: COLORS.WHITE + 'A0',
                        border: `${size({ p: 0.05 })} solid ${COLORS.C2}`
                    }}>
                    {
                        Object.keys(d1).map((k) => {
                            const Icon = d1[k].icon
                            return (
                                <Grid item container flexWrap={'nowrap'} columnGap={size({ p: 0.8 })}
                                    alignItems={'center'} justifyContent={'center'}>
                                    <Grid item>
                                        <Icon
                                            width={size({ p: 2.5 })}
                                            height={size({ p: 2.5 })}
                                            fill={COLORS.C2} />
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{
                                            fontFamily: 'Archia',
                                            fontSize: size({ p: 0.8 }),
                                            fontWeight: 500,
                                            color: COLORS.C2,
                                        }}>
                                            {k}<br />
                                            <Box component={'span'} sx={{
                                                fontSize: size({ p: 1 }),
                                                fontWeight: 700,
                                            }}>
                                                {syllabus[k]}
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Grid item container flexWrap={'nowrap'} alignItems={'start'} width={size({ p: 55 })}
                    justifyContent={'center'} columnGap={size({ p: 4 })}>
                    <Grid item container flexWrap={'nowrap'} direction={'column'}
                        rowGap={size({ p: 1, h: true })}>
                        <Grid item>
                            <Typography sx={{
                                fontFamily: 'Archia',
                                fontSize: size({ p: 1.2 }),
                                fontWeight: 600,
                                color: COLORS.C2,
                            }}>
                                About this course
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{
                                fontFamily: 'Space Mono',
                                fontSize: size({ p: 0.8 }),
                                fontWeight: 400,
                                color: COLORS.BLACK + "A0",
                                textAlign: 'justify'
                            }}>
                                {syllabus.about}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container width={size({ p: 25 })}>
                        <Grid item>
                            <Typography sx={{
                                fontFamily: 'Archia',
                                fontSize: size({ p: 1.2 }),
                                fontWeight: 600,
                                color: COLORS.C2,
                            }}>
                                Skills you'll gain
                            </Typography>
                        </Grid>
                        <Grid item>
                            <List>
                                {syllabus.skills.map((p, i) => {
                                    return (
                                        <ListItem disablePadding key={`skills_data_${i}`} sx={{
                                            padding: `${size({ p: 0.1 })} 0px ${size({ p: 0.1 })} 0px`,
                                        }}>
                                            <ListItemIcon sx={{
                                                minWidth: 0
                                            }}>

                                                <PatchIcon
                                                    fill={COLORS.C2}
                                                    width={size({ p: 1 })}
                                                    height={size({ p: 1 })} />                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={{
                                                    fontFamily: 'Space Mono',
                                                    fontSize: size({ p: 0.8 }),
                                                    fontWeight: 400,
                                                    color: COLORS.BLACK + 'A0',
                                                    paddingLeft: size({ p: 0.4 })
                                                }}>
                                                    {p}
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item width={size({ p: 58 })} sx={{
                    background: COLORS.WHITE,
                    border: `${size({ p: 0.08 })} solid ${COLORS.C2}`,
                }}>
                    <Grid container flexWrap={'nowrap'} height={size({ p: 12, h: true })}
                        alignItems={'center'} sx={{
                            padding: `0px ${size({ p: 1.5 })}`,
                        }}>
                        <Grid item container flexWrap={'nowrap'} direction={'column'}
                            rowGap={size({ p: 0.8, h: true })}>
                            <Grid item>
                                <Typography sx={{
                                    fontFamily: 'Archia',
                                    fontSize: size({ p: 1.5 }),
                                    fontWeight: 600,
                                    color: COLORS.C2
                                }}>
                                    Syllabus
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography sx={{
                                    fontFamily: 'Archia',
                                    fontSize: size({ p: 0.9 }),
                                    fontWeight: 400,
                                    color: COLORS.BLACK + 'A0'
                                }}>
                                    {Object.keys(syllabus.modules).length} Modules
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item width={size({ p: 30 })}>
                            <Typography component={'span'} onClick={() => setAllExpanded(!allExpanded)} sx={{
                                fontFamily: 'Space Mono',
                                fontSize: size({ p: 0.9 }),
                                fontWeight: 600,
                                color: COLORS.C6,
                                textAlign: 'right',
                                cursor: 'pointer',
                                borderRadius: size({ p: 0.2 }),
                                padding: `${size({ p: 0.3, h: true })} ${size({ p: 0.5 })}`,
                                '&:hover': {
                                    backgroundColor: COLORS.BLACK + '10'
                                },
                                float: 'right'
                            }}>
                                {allExpanded ? 'Collapse all sections' : 'Expand all sections'}
                            </Typography>
                        </Grid>
                    </Grid>
                    {
                        Object.keys(syllabus.modules).map((k, i) => {
                            return (
                                <Accordion disableGutters={true} expanded={expanded === i || allExpanded}
                                    onChange={handleChange(i)} sx={{
                                        border: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: COLORS.WHITE,
                                        padding: 0,
                                        margin: 0,
                                    }}>
                                    <AccordionSummary
                                        expandIcon={
                                            <ExpandMoreIcon sx={{
                                                color: COLORS.C1,
                                                fontSize: size({ p: 2.5 })
                                            }} />
                                        }
                                        sx={{
                                            height: size({ p: 9, h: true }),
                                            borderTop: `${size({ p: 0.08 })} solid ${COLORS.C2}`,
                                        }}>
                                        <Grid container flexWrap={'nowrap'} alignItems={'center'} sx={{
                                            padding: `0px ${size({ p: 0.5 })}`,
                                        }}>
                                            <Grid item>
                                                <Typography sx={{
                                                    fontFamily: 'Space Mono',
                                                    fontSize: size({ p: 1.1 }),
                                                    fontWeight: 600,
                                                    color: COLORS.WHITE,
                                                    backgroundColor: COLORS.C2,
                                                    borderRadius: '50%',
                                                    height: size({ p: 2.5 }),
                                                    width: size({ p: 2.5 }),
                                                    textAlign: 'center',
                                                    paddingTop: size({ p: 0.43 })
                                                }}>
                                                    {i + 1}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography sx={{
                                                    fontFamily: 'Space Mono',
                                                    fontSize: size({ p: 1.1 }),
                                                    fontWeight: 600,
                                                    color: COLORS.C2,
                                                    marginLeft: size({ p: 1 })
                                                }}>
                                                    {k}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{
                                        paddingLeft: size({ p: 6 }),
                                        marginTop: size({ p: 1, h: true, neg: true, })
                                    }}>
                                        <List>
                                            {syllabus.modules[k].map((p, j) => {
                                                return (
                                                    <ListItem disablePadding key={`modules_data_${j}`}>
                                                        <ListItemIcon sx={{
                                                            minWidth: 0
                                                        }}>
                                                            <PatchIcon
                                                                fill={COLORS.C2}
                                                                width={size({ p: 1.2 })}
                                                                height={size({ p: 1.2 })} />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            <Typography sx={{
                                                                fontFamily: 'Space Mono',
                                                                fontSize: size({ p: 1 }),
                                                                fontWeight: 400,
                                                                color: COLORS.BLACK + 'A0',
                                                                paddingLeft: size({ p: 0.6 })
                                                            }}>
                                                                {p}
                                                            </Typography>
                                                        </ListItemText>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    }
                </Grid>
                <Grid item height={size({ p: 10, h: true })}>
                    <Button variant="contained"
                        onClick={() => {
                            setOpen(true)
                        }}
                        sx={{
                            backgroundColor: COLORS.C2,
                            fontFamily: 'Space Mono',
                            fontWeight: 400,
                            fontSize: size({ p: 1 }),
                            height: size({ p: 5, h: true }),
                            width: size({ p: 12 }),
                            textTransform: 'none',
                            ':hover': {
                                backgroundColor: COLORS.C1,
                            },
                        }}>Enroll</Button>
                </Grid>
            </Grid>
            <Grid container flexWrap={'nowrap'} width={size({ p: 100 })} height={size({ p: 65, h: true })}
                mt={size({ p: 8, h: true })}
                columnGap={size({ p: 2 })} alignItems={'center'} justifyContent={'center'} sx={{
                    position: 'relative',
                }}>
                {
                    Object.keys(syllabus.reviews).map((i) => {
                        return (
                            <Grid item width={size({ p: 16 })} height={size({ p: 50, h: true })} sx={{
                                position: 'relative',
                                backgroundColor: CLRS[i],
                                border: `${size({ p: 0.05 })} solid ${COLORS.C2}`
                            }}>
                                <div style={{
                                    top: size({ p: 1.6, h: true }),
                                    left: size({ p: 0.8, neg: true }),
                                    position: 'absolute',
                                    width: size({ p: 0.8 }),
                                    height: size({ p: 50, h: true }),
                                    backgroundColor: 'transparent',
                                    backgroundImage: `
                                    radial-gradient(${COLORS.BLACK} ${size({ p: 0.025 })}, transparent ${size({ p: 0.025 })})`,
                                    backgroundSize: `${size({ p: 0.2 })} ${size({ p: 0.2 })}`,
                                }} />
                                <div style={{
                                    bottom: size({ p: 1.8, h: true, neg: true }),
                                    left: size({ p: 0.01, neg: true }),
                                    position: 'absolute',
                                    width: size({ p: 15.4 }),
                                    height: size({ p: 1.9, h: true }),
                                    backgroundColor: 'transparent',
                                    backgroundImage: `
                                    radial-gradient(${COLORS.C1} ${size({ p: 0.025 })}, transparent ${size({ p: 0.025 })})`,
                                    backgroundSize: `${size({ p: 0.2 })} ${size({ p: 0.2 })}`,
                                }} />
                                <QuotesLeftIcon
                                    fill={COLORS.C2}
                                    width={size({ p: 3.5 })}
                                    height={size({ p: 3.5 })}
                                    style={{
                                        position: 'absolute',
                                        top: size({ p: 3.2, h: true, neg: true }),
                                        left: size({ p: 1 })
                                    }} />
                                <div style={{
                                    position: 'relative',
                                }}>
                                    <Grid container flexWrap={'nowrap'} direction={'column'}
                                        height={size({ p: 44, h: true })}
                                        rowGap={size({ p: 1, h: true })} mt={size({ p: 6, h: true })}
                                        sx={{
                                            padding: `0px ${size({ p: 1 })}`
                                        }}>
                                        <Grid item>
                                            <Typography sx={{
                                                fontFamily: 'Space Mono',
                                                fontSize: size({ p: 0.8 }),
                                                fontWeight: 600,
                                                color: COLORS.C2,
                                            }}>
                                                {syllabus.reviews[i].review}
                                            </Typography>
                                        </Grid>
                                        <Grid item sx={{
                                            position: 'absolute',
                                            bottom: size({ p: 3, h: true }),
                                        }}>
                                            <Typography sx={{
                                                fontFamily: 'Space Mono',
                                                fontSize: size({ p: 0.8 }),
                                                fontWeight: 200,
                                                color: COLORS.BLACK + 'A0',
                                            }}>
                                                {syllabus.reviews[i].name}<br />Verified Student
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Enroll onClose={() => setOpen(false)} data={data} open={open} />
        </div>
    )
}