import React from "react";
import { size, COLORS } from "../../utils";
import { Grid, Typography } from "@mui/material"
import projects from '../../assets/lottiefiles/projects.json'
import Lottie from 'react-lottie';

export default function Intro() {
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'} width={size({ p: 70 })} height={size({ p: 100, h: true })}>
            <Grid item container flexWrap={'nowrap'} direction={'column'}
                rowGap={size({ p: 1, h: true })}>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 2.2 }),
                            fontWeight: 600,
                            textAlign: 'center',
                            color: `${COLORS.C2}`,
                            padding: `0px ${size({ p: 10 })}`
                        }}
                    >
                        Take a smarter approach to your final year projects with Genesys
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 1 }),
                            fontWeight: 400,
                            textAlign: 'center',
                            color: `${COLORS.C1}A0`,
                            padding: `0px ${size({ p: 5 })}`
                        }}
                    >
                        Elevate your final year projects with Genesys – where innovation meets expertise. Take a smarter approach as we guide you through cutting-edge solutions and empower your projects to stand out in the tech landscape. Transform your ideas into successful realities with Genesys by your side.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item mt={size({ p: 3, h: true, neg: true })}>
                <Lottie width={size({ p: 35 })} height={size({ p: 35 })}
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: projects,
                    }} />
            </Grid>
        </Grid >
    )
}