import React from "react"
import { Grid, Typography } from "@mui/material"
import { size, COLORS } from "../../utils"

const DATA = {
    LEFT: [
        { img: '/assets/images/gallery/ClassRoom.jpg', title: 'Class Room' },
        { img: '/assets/images/gallery/Gen8Year1.jpg', title: 'Genesys 8th Anniversary Celebration' },
        { img: '/assets/images/gallery/AyudhaPooja1.jpg', title: 'Ayudha Pooja Celebration' },

    ],
    CENTER: [
        { img: '/assets/images/gallery/FrontOffice.jpg', title: 'Front Office' },
        { img: '/assets/images/gallery/Logo.jpg', title: '' },
        { img: '/assets/images/gallery/Lab1.jpg', title: 'Lab 1' },
        { img: '/assets/images/gallery/PECWorkshopPoster.jpg', title: '' },
        { img: '/assets/images/gallery/PECWorkshop1.jpg', title: 'Panimalar Workshop' },
        { img: '/assets/images/gallery/Cake8Year.jpg', title: '' },
        { img: '/assets/images/gallery/PECWorkshop2.jpg', title: 'Panimalar Workshop' },
    ],
    RIGHT: [
        { img: '/assets/images/gallery/Lab2.jpg', title: 'Lab 2' },
        { img: '/assets/images/gallery/Gen8Year1.jpg', title: 'Genesys 8th Anniversary Celebration' },
        { img: '/assets/images/gallery/AyudhaPooja2.jpg', title: 'Ayudha Pooja Celebration' },
    ]
}

function GalleryTile(props) {
    const { data, height } = props
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'} width={size({ p: 25 })}
            justifyContent={'flex-end'} rowGap={size({ p: 1, h: true })}
            height={size({ p: data.title.length === 0 ? 30 : height, h: true })} sx={
                data.title.length === 0 ? {
                    borderRadius: size({ p: 1.2 }),
                    position: 'relative',
                    background: `linear-gradient(180deg,rgba(0,0,0,0) 100%,rgba(0,0,0,.88) 0%), url(${data.img}) center`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                } : {
                    borderRadius: size({ p: 1.2 }),
                    position: 'relative',
                    background: `linear-gradient(180deg,rgba(0,0,0,0) 50.89%,rgba(0,0,0,.88) 90.56%), url(${data.img}) center`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    paddingLeft: size({ p: 2.2 }),
                    paddingRight: size({ p: 1 }),
                    paddingBottom: size({ p: 2, h: true }),
                }}>
            {
                data.title.length === 0 ? (<></>) : (
                    <Grid item>
                        <Typography sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 1.5 }),
                            fontWeight: 600,
                            color: COLORS.WHITE,
                        }}>
                            {data.title}
                        </Typography>
                    </Grid>
                )
            }
        </Grid>
    )
}

export default function GalleryC() {
    return (
        <Grid item container flexWrap={'nowrap'} direction={"column"} alignItems={'center'}
            mt={size({ p: 16, h: true })} rowGap={size({ p: 10, h: true })}>
            <Grid item container flexWrap={'nowrap'} direction={"column"} alignItems={'center'}
                rowGap={size({ p: 2, h: true })}>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 2.2 }),
                            fontWeight: 600,
                            textAlign: 'center',
                            color: `${COLORS.C2}`,
                        }}
                    >
                        Capturing Moments, Inspiring Journeys
                    </Typography>
                </Grid>
                <Grid item width={size({ p: 60 })}>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 0.9 }),
                            fontWeight: 500,
                            textAlign: 'center',
                            color: `${COLORS.C1}A0`,
                            padding: `0px ${size({ p: 7 })}`
                        }}
                    >
                        Discover the faces driving innovation – meet the dedicated team behind your next project at Genesys. Together, we turn ideas into transformative solutions.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container flexWrap={'nowrap'} alignItems={'center'}
                justifyContent={'center'} width={size({ p: 79 })}>
                <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
                    justifyContent={'center'} rowGap={size({ p: 3, h: true })}>
                    {
                        DATA.LEFT.map((dat) => {
                            return (
                                <GalleryTile data={dat} height={62} />
                            )
                        })
                    }
                </Grid>
                <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
                    justifyContent={'center'} rowGap={size({ p: 3, h: true })}>
                    {
                        DATA.CENTER.map((dat) => {
                            return (
                                <GalleryTile data={dat} height={35} />
                            )
                        })
                    }
                </Grid>
                <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
                    justifyContent={'center'} rowGap={size({ p: 3, h: true })}>
                    {
                        DATA.RIGHT.map((dat) => {
                            return (
                                <GalleryTile data={dat} height={62} />
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}