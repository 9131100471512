import * as React from "react";

function JupyterIcon(props) {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            height="1em"
            width="1em"
            {...props}
        >
            <path d="M7.157 22.201A1.784 1.799 0 015.374 24a1.784 1.799 0 01-1.784-1.799 1.784 1.799 0 011.784-1.799 1.784 1.799 0 011.783 1.799zM20.582 1.427a1.415 1.427 0 01-1.415 1.428 1.415 1.427 0 01-1.416-1.428A1.415 1.427 0 0119.167 0a1.415 1.427 0 011.415 1.427zM4.992 3.336A1.047 1.056 0 013.946 4.39a1.047 1.056 0 01-1.047-1.055A1.047 1.056 0 013.946 2.28a1.047 1.056 0 011.046 1.056zm7.336 1.517c3.769 0 7.06 1.38 8.768 3.424a9.363 9.363 0 00-3.393-4.547 9.238 9.238 0 00-5.377-1.728A9.238 9.238 0 006.95 3.73a9.363 9.363 0 00-3.394 4.547c1.713-2.04 5.004-3.424 8.772-3.424zm.001 13.295c-3.768 0-7.06-1.381-8.768-3.425a9.363 9.363 0 003.394 4.547A9.238 9.238 0 0012.33 21a9.238 9.238 0 005.377-1.729 9.363 9.363 0 003.393-4.547c-1.712 2.044-5.003 3.425-8.772 3.425z" />
        </svg>
    );
}

export default JupyterIcon;
