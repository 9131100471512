import React from 'react';
import { Grid } from '@mui/material';
import Courses from './Courses';
import ResearchAssistance from './ResearchAssistance';
import Projects from './Projects';
import Workshops from './Workshops';
import Careers from './Careers';
import Success from './Success';

export default function Home() {
    return (
        <Grid container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'}>
            <Courses />
            <ResearchAssistance />
            <Projects />
            <Workshops />
            <Careers />
            <Success />
        </Grid>
    )
}