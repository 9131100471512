import { Grid, Tabs as MuiTabs } from "@mui/material";
import React, { useState } from 'react';
import Tab from './Tab'
import TabPanel from './TabPanel'
import { size, COLORS } from "../../utils";

function Tabs(props) {
    const { data } = props
    const [currentTabIndex, setCurrentTabIndex] = useState(0)

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const handleChange = (_, newValue) => {
        setCurrentTabIndex(newValue);
    };

    return (
        <Grid item container mt={size({ p: 3, h: true })} alignItems={'center'} justifyContent={'center'}>
            <Grid item width={size({ p: 70 })}>
                <MuiTabs value={currentTabIndex} onChange={handleChange} variant="fullWidth"
                    scrollButtons={true}
                    sx={{
                        ".MuiTabs-indicator": {
                            bgcolor: COLORS.C2,
                            height: size({ p: 0.5, h: true }),
                        },
                        borderBottom: `${size({ p: 0.3, h: true })} solid ${COLORS.C2}`
                    }}>
                    {
                        Object.keys(data).map((t, i) => {
                            return (
                                <Tab label={t} {...a11yProps({ i })} />
                            )
                        })
                    }
                </MuiTabs>
            </Grid>
            <TabPanel currentTabIndex={currentTabIndex} setCurrentTabIndex={setCurrentTabIndex} data={data} />
        </Grid>
    );
}

export default Tabs;