import React, { useState } from "react";
import { Grid, Box, Typography, List, ListItem, ListItemIcon, ListItemText, Button } from "@mui/material";
import { DATA } from "./Data";
import { size, COLORS } from '../../utils'
import PatchIcon from '../../assets/icons/patch'
import Apply from "./Apply";

export default function Career() {
    const Data = [DATA.slice(0, 2), DATA.slice(2, 4)]
    const [open, setOpen] = useState(false)
    const [applyData, setApplyData] = useState({})
    return (
        <Grid item container flexWrap={'nowrap'} direction={'column'}
            alignItems={'center'} justifyContent={'center'}
            width={size({ p: 100 })} rowGap={size({ p: 8, h: true })}
            marginTop={size({ p: 2, h: true })} marginBottom={size({ p: 20, h: true })}>
            <Grid item container flexWrap={'nowrap'} direction={'column'} alignItems={'center'} justifyContent={'center'}
                rowGap={size({ p: 1, h: true })}>
                <Grid item>
                    <Typography
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 3 }),
                            fontWeight: 700,
                            textAlign: 'left',
                            color: COLORS.C2,
                        }}
                    >
                        Career Oppurtunities
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography id={'career_ops'}
                        sx={{
                            fontFamily: 'Archia',
                            fontSize: size({ p: 1 }),
                            fontWeight: 400,
                            textAlign: 'center',
                            color: `${COLORS.C1}A0`,
                            padding: `0px ${size({ p: 25 })}`
                        }}
                    >
                        Join our dynamic team, where your expertise is valued, and together, let's shape the future of technology.
                    </Typography>
                </Grid>
            </Grid>
            {
                Data.map((k) => {
                    return (
                        <Grid item container
                            alignItems={'center'} justifyContent={'center'}
                            width={size({ p: 100 })} columnGap={size({ p: 5 })}>
                            {
                                k.map((dat, i) => {
                                    return (
                                        <Box position={'relative'}>
                                            <Box sx={{
                                                zIndex: 1,
                                                width: size({ p: 25 }),
                                                height: size({ p: 70, h: true }),
                                                position: 'absolute',
                                                top: size({ p: 2, h: true }),
                                                right: (i === 0) ? size({ p: 1 }) : 'auto',
                                                left: (i === 1) ? size({ p: 1 }) : 'auto',
                                                backgroundColor: dat.bgcolor,
                                                border: `${size({ p: 0.1 })} solid ${COLORS.BLACK}`,
                                                borderRadius: size({ p: 0.6 })
                                            }} />
                                            <Box sx={{
                                                zIndex: 2,
                                                width: size({ p: 25 }),
                                                height: size({ p: 70, h: true }),
                                                position: 'relative',
                                                backgroundColor: dat.bgcolor.slice(0, 7),
                                                border: `${size({ p: 0.1 })} solid ${COLORS.BLACK}`,
                                                borderRadius: size({ p: 0.6 }),
                                                padding: `${size({ p: 2, h: true })} ${size({ p: 1.5 })}`
                                            }}>
                                                <Grid item container flexWrap={'nowrap'} direction={'column'}
                                                    rowGap={size({ p: 1.5, h: true })}>
                                                    <Grid item>
                                                        <Typography sx={{
                                                            fontFamily: 'Space Mono',
                                                            fontSize: size({ p: 1.2 }),
                                                            fontWeight: 700,
                                                            color: `${COLORS.BLACK + 'E0'}`,
                                                        }}>
                                                            {dat.Title}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography sx={{
                                                            fontFamily: 'Space Mono',
                                                            fontSize: size({ p: 0.9 }),
                                                            color: COLORS.BLACK + 'E0',
                                                        }}>
                                                            {dat.BasicDescription}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item container flexWrap={'nowrap'} direction={'column'} width={size({ p: 25 })}
                                                        pt={size({ p: 0.8, h: true })}>
                                                        <Grid item>
                                                            <Typography sx={{
                                                                fontFamily: 'Space Mono',
                                                                fontSize: size({ p: 1 }),
                                                                fontWeight: 700,
                                                                color: COLORS.BLACK + 'E0',
                                                            }}>
                                                                Skills Required
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <List>
                                                                {dat.Skills.map((p, i) => {
                                                                    return (
                                                                        <ListItem disablePadding key={`skills_data_${i}`} sx={{
                                                                            padding: `${size({ p: 0.1 })} 0px ${size({ p: 0.1 })} 0px`,
                                                                        }}>
                                                                            <ListItemIcon sx={{
                                                                                minWidth: 0
                                                                            }}>
                                                                                <PatchIcon
                                                                                    fill={COLORS.BLACK + 'A0'}
                                                                                    width={size({ p: 1 })}
                                                                                    height={size({ p: 1 })} />                                                                            </ListItemIcon>
                                                                            <ListItemText>
                                                                                <Typography sx={{
                                                                                    fontFamily: 'Space Mono',
                                                                                    fontSize: size({ p: 0.8 }),
                                                                                    fontWeight: 400,
                                                                                    color: COLORS.BLACK + 'A0',
                                                                                    paddingLeft: size({ p: 0.5 }),
                                                                                    width: size({ p: 20 })
                                                                                }}>
                                                                                    {p}
                                                                                </Typography>
                                                                            </ListItemText>
                                                                        </ListItem>
                                                                    )
                                                                })}
                                                            </List>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{
                                                        display: 'flex',
                                                        alignItems: 'flex-end',
                                                        justifyContent: 'flex-end'
                                                    }}>
                                                        <Button variant="contained"
                                                            onClick={() => {
                                                                setApplyData(dat)
                                                                setOpen(true)
                                                            }}
                                                            sx={{
                                                                backgroundColor: `${COLORS.C1}`,
                                                                fontFamily: 'Space Mono',
                                                                fontWeight: 400,
                                                                fontSize: size({ p: 0.8 }),
                                                                textTransform: 'none',
                                                                ':hover': {
                                                                    backgroundColor: `${COLORS.C1}`,
                                                                },
                                                            }}>{'Apply Now ->'}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </Grid>
                    )
                })
            }
            <Apply onClose={() => setOpen(false)} data={applyData} open={open} />
        </Grid >
    )
}