import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Header from './Components/Header'
import Home from './Components/Home'
import Courses from "./Components/Courses";
import { DATA as COURSE_DATA } from './Components/Courses/Data'
import CourseExplore from './Components/Courses/Explore';
import ResearchAssistance from "./Components/ResearchAssistance";
import Projects from "./Components/Projects";
import Workshops from "./Components/Workshops";
import { DATA as WORKSHOP_DATA } from './Components/Workshops/Data'
import WorkshopExplore from './Components/Workshops/Explore';
import Careers from "./Components/Careers";
import Gallery from "./Components/Gallery";
import Footer from "./Components/Footer";
import EnquireForm from "./Components/ContactUs";

function App() {

  var COURSE_ROUTES = []
  for (let z = 0; z < Object.keys(COURSE_DATA).length; z++) {
    const k1 = Object.keys(COURSE_DATA)[z]
    for (let y = 0; y < Object.keys(COURSE_DATA[k1]).length; y++) {
      const k2 = Object.keys(COURSE_DATA[k1])[y]
      COURSE_ROUTES = [
        ...COURSE_ROUTES,
        <Route
          path={`/courses/${COURSE_DATA[k1][k2].name.split(' ').join('').toLowerCase()}`}
          element={<CourseExplore data={COURSE_DATA[k1][k2]} />}
        />
      ]
    }
  }

  var WORKSHOP_ROUTES = []
  for (let z = 0; z < Object.keys(WORKSHOP_DATA).length; z++) {
    const k1 = Object.keys(WORKSHOP_DATA)[z]
    if (k1 !== 'ASSOCIATED') {
      for (let y = 0; y < Object.keys(WORKSHOP_DATA[k1]).length; y++) {
        const k2 = Object.keys(WORKSHOP_DATA[k1])[y]
        WORKSHOP_ROUTES = [
          ...WORKSHOP_ROUTES,
          <Route
            path={`/workshops/${k1.split(' ').join('').toLowerCase()
              }/${WORKSHOP_DATA[k1][k2].name.split(' ').join('').toLowerCase()
              }`}
            element={<WorkshopExplore data={WORKSHOP_DATA[k1][k2]} />}
          />
        ]
      }
    }
  }

  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/courses" element={<Courses />} />
        <Route path="/contact_us" element={<EnquireForm />} />
        <Route path="/research_assistance" element={<ResearchAssistance />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/workshops" element={<Workshops />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/gallery" element={<Gallery />} />
        {COURSE_ROUTES}
        {WORKSHOP_ROUTES}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
