import React from 'react';
import { Grid } from '@mui/material';
import Intro from './Intro';
import Workshop from './Workshop';

export default function Workshops() {
    return (
        <Grid container flexWrap={'nowrap'} direction={'column'} alignItems={'center'}
            justifyContent={'center'}>
            <Intro />
            <Workshop />
        </Grid>
    )
}